import { useEffect, useState, useCallback, useRef } from "react";
import { WmPhotoBrief } from "../app/TakePhoto";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import WmeterForm from "./WmeterForm";
import ImageViewer from "react-simple-image-viewer";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import useTaskDetailsFormControlStore from "../stores/TaskDetailsFormControlStore";
import useDialogStore from "../stores/useDialogStore";

const WmWorkListAcrdn = (props) => {
  const [propState, setPropState] = useState(props);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [defaultPhysicalPlaces, setDefaultPhysicalPlaces] = useState({});

  const { isButtonDisabled } = useTaskDetailsFormControlStore((state) => ({
    isButtonDisabled: state.isButtonDisabled,
  }));

  const { openModal, closeModal } = useDialogStore();

  const propStateListActTask = useRef(props.wmWorkList[`${props.actTask}`]);

  useEffect(() => {
    setPropState(props);
    propStateListActTask.current = props.wmWorkList[`${props.actTask}`];
  }, [props]);

  const handleWorkNewOrOldDelete = (index, type) => {
    openModal({
      title: type === "new" ? "Felszerelt vízóra törlése" : "Leszerelt vízóra törlése",
      confirmation: "Biztosan törölni szeretné ezt a vízórát?",
      onConfirm: () => {
        onDeleteButtonClick(index);
        closeModal();
      },
      onCancel: closeModal,
    });
  };

  const handleImageDelete = (workitemIdx, imageIdx) => {
    openModal({
      title: "Kép törlése",
      confirmation: "Biztosan törölni szeretné ezt a képet?",
      onConfirm: () => {
        onWmThDelClick(workitemIdx, imageIdx);
        closeModal();
      },
      onCancel: closeModal,
    });
  };

  const handleDeleteAll = (curr_wm_number) => {
    openModal({
      title: "Felszerelt és Leszerelt törlése",
      confirmation: "Biztosan törölni szeretné a leszerelt és felszerelt vízórát is?",
      onConfirm: () => {
        propStateListActTask.current.wmlist
          .filter((item) => item.wm_number === curr_wm_number)
          .map((item) => propStateListActTask.current.wmlist.indexOf(item))
          .reverse()
          .forEach((index) => onDeleteButtonClick(index));
        closeModal();
      },
      onCancel: closeModal,
    });
  };

  const onDeleteButtonClick = (index) => {
    let curr_wm_number = propStateListActTask.current.wmlist[index].wm_number;
    propStateListActTask.current.wmlist.splice(index, 1);

    if (propStateListActTask.current.wmlist.filter((item) => item.wm_number === curr_wm_number).length === 0) {
      propState.setActiveKey(null);
      propStateListActTask.current.wmlist.filter((item) => item.wm_number > curr_wm_number).forEach((item) => item.wm_number--);
    } else {
      propState.setActiveWmTabKey(!propState.activeWmTabKey);
    }

    /// Adjusting the ID list duties to the spliced watermeter list:
    propState.wmList.current = propState.wmList.current.map((item) => {
      if (item.duty === index) item.duty = null;
      if (item.duty > index) item.duty--;
      return item;
    });
    propState.sealList.current = propState.sealList.current.map((item) => {
      if (item.duty === index) item.duty = null;
      if (item.duty > index) item.duty--;
      return item;
    });
  };

  const onThumbnailClick = (index) => {
    openImageViewer(index);
  };
  const onWmThDelClick = (workitemIdx, imageIdx) => {
    const tempState = Object.assign({}, propState.wmWorkList);
    tempState[`${propState.actTask}`].wmlist[workitemIdx].images.splice(imageIdx, 1);
    propState.SetWmWorkList(tempState);
  };
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  let content = null;
  if (propStateListActTask.current && propStateListActTask.current.wmlist)
    propStateListActTask.current.wmlist
      .map((item) => item.wm_number)
      .sort()
      .filter((value, index, arr) => arr.indexOf(value) === index)
      .forEach((curr_wm_number) => {
        let waterMeters = null;

        propStateListActTask.current.wmlist
          .filter((item) => item.wm_number === curr_wm_number)
          .forEach((wm_data_row) => {
            let index = propStateListActTask.current.wmlist.indexOf(wm_data_row);
            let body = (
              <Tab key={wm_data_row.is_new.toString() + "wmtab"} eventKey={wm_data_row.is_new} title={wm_data_row.is_new ? "Felszerelt" : "Leszerelt"}>
                <Button
                  variant="warning"
                  onClick={() => handleWorkNewOrOldDelete(index, wm_data_row.is_new ? "new" : "old")}
                  className="mr-4  mb-2"
                  disabled={!!wm_data_row.id || isButtonDisabled}
                >
                  {wm_data_row.is_new ? "Új vízóra törlése" : "Régi vízóra törlése"}
                </Button>
                <WmeterForm
                  taskList={propState.taskList}
                  wmWorkList={propState.wmWorkList}
                  SetWmWorkList={propState.SetWmWorkList}
                  actTask={propState.actTask}
                  item={index}
                  key={index.toString() + "meterform"}
                  keyProp={index.toString()}
                  focusRefList={propState.focusRefList}
                  sealList={propState.sealList}
                  wmList={propState.wmList}
                  defaultPhysicalPlace={defaultPhysicalPlaces[curr_wm_number]}
                  setDefaultPhysicalPlaces={(place) =>
                    setDefaultPhysicalPlaces({
                      [curr_wm_number]: place,
                    })
                  }
                />
                <div>
                  <WmPhotoBrief
                    taskList={propState.taskList}
                    anyWorkList={propState.wmWorkList}
                    SetAnyWorkList={propState.SetWmWorkList}
                    actTask={propState.actTask}
                    item={index}
                    key={index.toString() + "wmphot"}
                    file_name_ext="w"
                  />
                  <>
                    <div style={{ columns: "2 auto" }}>
                      {propStateListActTask.current.wmlist[index].images.map((imgItem, idx) => (
                        <div key={idx.toString() + "dv"}>
                          <img src={imgItem.image} alt="Wm-app kép" width="100%" onClick={() => onThumbnailClick(idx)} />
                          <br />
                          <Button
                            disabled={!!propStateListActTask.current.wmlist[index].id || isButtonDisabled}
                            key={idx.toString() + "wmdelbut"}
                            variant="danger"
                            onClick={() => handleImageDelete(index, idx)}
                            className="py-0 mt-1 mb-2"
                          >
                            Kép törlése
                          </Button>
                        </div>
                      ))}
                    </div>
                    {isViewerOpen && (
                      <ImageViewer src={propStateListActTask.current.wmlist[index].images.map((imgRec) => imgRec.image)} currentIndex={currentImage} onClose={closeImageViewer} />
                    )}
                  </>
                </div>
              </Tab>
            );
            if (waterMeters != null) waterMeters = [waterMeters, body];
            else waterMeters = body;
          });

        //The top layer header, event key set by wm_number
        let header = (
          <Card key={curr_wm_number.toString() + "wmlist"}>
            <Card.Header style={{ textAlign: "center" }} key={curr_wm_number.toString() + "wmlistHeader"}>
              <Accordion.Toggle as={Button} variant="link" eventKey={curr_wm_number.toString()}>
                <h4 variant="light">Vízóra {curr_wm_number + 1}</h4>
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={curr_wm_number.toString()}>
              <Card.Body>
                <Tabs
                  style={{ textAlign: "center", fontWeight: "bold" }}
                  activeKey={propState.activeWmTabKey}
                  onSelect={(k) => propState.setActiveWmTabKey(k)}
                  className="mb-3 mr-2"
                >
                  {waterMeters}
                </Tabs>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteAll(curr_wm_number)}
                  className="mr-4  mb-2"
                  disabled={!!propStateListActTask.current.wmlist.filter((item) => item.wm_number === curr_wm_number).map((item) => item.id)[0] || isButtonDisabled}
                >
                  Felszerelt és Leszerelt törlése
                </Button>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
        if (content != null) content = [content, header];
        else content = header;
      });

  if (propStateListActTask.current && propStateListActTask.current.wmlist) {
    return (
      <>
        <>{content}</>
      </>
    );
  }
  return null;
};
export default WmWorkListAcrdn;
