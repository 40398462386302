import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react'
import { BrowserMultiFormatReader, BarcodeFormat, DecodeHintType } from '@zxing/library'
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';

const BarcodeScannerComponent = props => {

    const [propState, setPropState] = useState(props);
    const [selectedDeviceId, SetSelectedDeviceId] = useState(null);
    const selectedDeviceIdRef = useRef(null);
    const selectedDeviceIdChanged = useRef(false);
    const selectDeviceRef = useRef(null);
    const videoRef = useRef(null);
    const selectableDevices = useRef([]);
    const isMountedComponent = useRef(true);
    const unmountingRef = useRef(false);



    useEffect(() => {

        if (isMountedComponent.current) {
            setPropState(props);
        }
        return () => {
            isMountedComponent.current = false;
            codeReader.reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);


    useEffect(() => {

        if (videoRef.current && selectedDeviceId) {
            console.log(`Selected video device from MAIN EFFECT ${selectedDeviceId}`);
            if (selectedDeviceIdRef.current !== selectedDeviceId) {
                if (selectedDeviceIdRef.current) {
                    codeReader.reset();
                    selectedDeviceIdChanged.current = true;
                }
                selectedDeviceIdRef.current = selectedDeviceId;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propState, selectedDeviceId, videoRef]);


    useEffect(() => {

        if (videoRef.current && selectedDeviceId && !unmountingRef.current) {

            codeReader.decodeOnceFromVideoDevice(selectedDeviceId, 'video').then(result => {
                unmountingRef.current = true;
                propState.onUpdate(null, result)
            }).catch((err) => {
                if (selectedDeviceIdChanged.current) {
                    selectedDeviceIdChanged.current = false;
                } else {
                    unmountingRef.current = true;
                    propState.onUpdate(err)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propState, selectedDeviceId, videoRef.current]);

    const onVideoDeviceChange = useCallback((e) => {
        e?.preventDefault();
        if (selectDeviceRef.current) {
            SetSelectedDeviceId(selectableDevices.current[selectDeviceRef.current.value].deviceId);
            localStorage.setItem("wm_device_index", selectDeviceRef.current.value);
        }
    }, []);


    const codeReader = useMemo(() => {

        const hints = new Map();
        hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.QR_CODE, BarcodeFormat.CODE_128]);

        console.log(`Codereader memo`);
        return (new BrowserMultiFormatReader(hints));
    }, [])


    useEffect( () => {
        (async function () {
            if (codeReader) {
                // selectableDevices.current = await (await codeReader.listVideoInputDevices()).map(d => ({'deviceId':d.deviceId, 'label': d.label}));
                selectableDevices.current = await codeReader.listVideoInputDevices();
                console.log(`selectable devices effect`);
                console.log(selectableDevices.current);
                let devIdx = localStorage.getItem("wm_device_index");
                if (devIdx === null) {
                    localStorage.setItem("wm_device_index", 0);
                    devIdx = 0;
                }
                SetSelectedDeviceId(selectableDevices.current[devIdx]?.deviceId);
            }
        })();

    }, [codeReader])



    const cancelBarcodeReader = useCallback((e) => {
        e?.preventDefault();
        unmountingRef.current = true;
        codeReader.reset();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeReader, propState.onUpdate]);


    if (selectableDevices.current?.length > 0)
        return (
            <div style={{ textAlign: "center" }}>
                <video id="video" width={propState.width} height={propState.height} ref={videoRef} ></video>
                <Form>
                    <Form.Group controlId="wmForm.SelectVideoDevice">
                        <Form.Label>Kamera kiválasztása:</Form.Label>
                        <Form.Control as="select" custom ref={selectDeviceRef} onChange={onVideoDeviceChange}
                            defaultValue={localStorage.getItem("wm_device_index") === null ? 0 : localStorage.getItem("wm_device_index")}>
                            {selectableDevices.current.map((device, iii) => (
                                <option key={iii} value={iii}>{device.label}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>

                <Button className="px-1 mx-0 py-0 " variant="success"
                    onClick={(e) => cancelBarcodeReader(e)}>Kilépés</Button>
            </div>
        )
    else
        return (
            <div style={{ textAlign: "center" }}>Kérem várjon...</div>
        );

}

export default BarcodeScannerComponent
