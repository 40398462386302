
//Constants for MessageBox
export const MESSAGEBOX_RESPONSE_YES = "MESSAGEBOX_RESPONSE_YES";
export const MESSAGEBOX_RESPONSE_NO = "MESSAGEBOX_RESPONSE_NO";
export const MESSAGEBOX_RESPONSE_CANCELLED = "MESSAGEBOX_RESPONSE_CANCELLED";

export const INVOICE_ITEM_EXTREM_PRICE = "-999999999999999.00";

export const PREFIX_WM = "watermeter_id";
export const PREFIX_SEAL = "seal_id";

// Image shrinking constants IMG_WIDTH (px), IMG_QUALITY (%),
// width is the larger size regardless of orientation
export const IMG_WIDTH = 1200;
export const IMG_QUALITY = 80;

// Constants for invoice status

export const INVOICE_STATUS_NOT_INVOICED_YET = 0
export const INVOICE_STATUS_TRANSFER_INVOICE_REQUIRED_BY_MECHANIC = 1
export const INVOICE_STATUS_GENERATED = 2

export const INVOICE_PAYMENT_CASH = 0;
export const INVOICE_PAYMENT_BANK_CARD = 1;
export const INVOICE_PAYMENT_TRANSFER = 2;