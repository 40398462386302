import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './polyfill';
import CameraError from './CameraError';
import CaptureButton from './CaptureButton';
import CameraWrapper from './CameraWrapper';
import CameraControls from './CameraControls';
import TorchButton from './torchButton';
import { errorTypes } from './errorTypes';
import { facingModes } from './facingModeTypes';
import { getAvailableDevices, checkTorch } from './cameraUtils';
//import Jimp from 'jimp';
import { IMG_WIDTH } from '../shared/types';

class Camera extends PureComponent {
    constructor(props) {
        super(props);
        const constraints = {
            video: {
                facingMode: { exact: "environment" },
                width: { min: 1024, ideal: IMG_WIDTH, max: IMG_WIDTH },
                height: { min: 576, ideal: 720, max: 720 }
            }
        };
        this.state = {
            constraints,
            devices: null,
            error: false,
            isIntersecting: false,
            mediaStream: null,
            hasTorch: false,
        };


    }

    async componentDidMount() {
        const devices = await getAvailableDevices('video');
        if (devices) {
            this.setState({ devices });
        }

        var constraints = {
            audio: false,
            video: {
                facingMode: "environment",
                zoom: true
            }
        };

        var cameraWithFlash = null;
        for (const dii of devices) {
            try {
                constraints.video.deviceId = `${dii.deviceId}`;
                const msii = await navigator.mediaDevices.getUserMedia(
                    constraints
                );
                try {
                    const videoTrack = msii?.getVideoTracks()[0];
                    if (videoTrack?.getCapabilities()?.facingMode[0] === "environment") {
                        const hasTorch = await checkTorch(videoTrack);
                        msii.getTracks().forEach(t => t.stop());
                        if (hasTorch) {
                            cameraWithFlash = dii;
                            break;
                        }
                    }
                } catch (error) {
                    console.log("ERROR FROM GETCONTRAINTS:")
                    console.log(error);
                }
                msii.getTracks().forEach(t => t.stop());
            } catch (error) {
                console.log("ERROR FROM GETUSERMEDIA:")
                console.log(error);
            }
        }
        constraints = cameraWithFlash
            ?
            {
                audio: false,
                video: { deviceId: `${cameraWithFlash.deviceId}` }
            }
            :
            {
                audio: false,
                video: {
                    facingMode: "environment"
                }
            };

        this.setState({ constraints });
        await this.getMediaStream(constraints);
        this.setVideoStream();
        const hasTorch = await checkTorch(this.state.mediaStream?.getVideoTracks()[0]);

        this.setState({ hasTorch });

        // Set up zoom slider:
        let videoTrack, zoomSettings, input;
        try {
            videoTrack = this.state.mediaStream?.getVideoTracks()[0];
            zoomSettings = videoTrack.getCapabilities().zoom;
            input = document.getElementById('zoomSlide');
        } catch (error) {
            console.error("Error setting up zoom slider: ", error);   
            return
        }
        

        if (!zoomSettings) {
            console.error("Zoom not avalible on this device.");
            input.style.display = 'none';
        } else {
            this.state.zoomSettings = {...zoomSettings, current: zoomSettings.min};
            input.min = zoomSettings.min;
            input.max = zoomSettings.max;
            input.step = zoomSettings.step;
            input.value = zoomSettings.min;
            input.style.display = 'block';
        }
    }

    componentWillUnmount() {
        this.stopMediaStream();
    }

    captureMediaStream = (event, mediaStream) => {
        if (!(this.video?.srcObject)) return;
        const ms = this.state.mediaStream;
        if (!ms) {
            this.setState({ error: errorTypes.NO_STREAM.type });
            return;
        }

        const mediaStreamTrack = ms.getVideoTracks()[0];
        const imageCapture = new window.ImageCapture(mediaStreamTrack);

        if (imageCapture) {
            this.takePhoto(imageCapture);
        }
    };

    torchOn = async () => {
        if (!!(this.video?.srcObject)) {
            const streamTrack = this.video.srcObject.getVideoTracks()[0];
            if (this.state.isFashOn) {
                this.setState({ isFashOn: false });
                console.log("                           IS ON TRUE FLAShON")
                await streamTrack.applyConstraints({
                    advanced: [{ torch: false }]
                });

            } else {
                this.setState({ isFashOn: true });
                console.log("                           IS ON FALSE FLAShON")
                await streamTrack.applyConstraints({
                    advanced: [{ torch: true }]
                }).catch(err => console.log(err));

            }
        }
    };

    async getMediaStream(constraints = {}) {
        if (!this.state.mediaStream) {
            try {
                const mediaStream = await navigator.mediaDevices.getUserMedia(
                    constraints
                );
                this.setState({ mediaStream });
            } catch (error) {
                console.log(error);
                this.setState({ error: errorTypes.UNSUPPORTED.type });
            }
        }
    }

    async takePhoto(imageCapture) {
        try {
            const { onTakePhoto } = this.props;
            const blob = await imageCapture.takePhoto({ imageWidth: `${IMG_WIDTH}` });
            const capturedImg = URL.createObjectURL(blob);
            
            if (onTakePhoto) {
                onTakePhoto(capturedImg);
            }
        } catch (e) {
            this.setState({ error: errorTypes.TAKE_PHOTO_FAILURE.type });
        }
    }

    setVideoStream() {
        const { mediaStream } = this.state;
        if (this.video && !this.video.srcObject) {
            this.video.srcObject = mediaStream;
            this.video.onloadedmetadata = () => this.video.play();
        }
    }
    async releaseVideoSrc() {
        if (!!(this.video?.srcObject)) {
            if (this.state.isFashOn) {
                const streamTrack = this.video.srcObject.getVideoTracks()[0];
                this.setState({ isFashOn: false });
                await streamTrack.applyConstraints({
                    advanced: [{ torch: false }]
                });
                await streamTrack.applyConstraints({
                    advanced: [{ torch: false }]
                });
                await streamTrack.applyConstraints({
                    advanced: [{ torch: false }]
                });
            }
            // seems unnecessary, but without this check exception arises
            // obvious reason: we are in the process of unmounting
            if (!!(this.video?.srcObject))
                this.video.srcObject = null;
        }
        this.state.mediaStream?.getTracks().forEach(t => t.stop());

    }
    async stopMediaStream() {
        const { onStopMediaStream } = this.props;
        await this.releaseVideoSrc();
        if (onStopMediaStream) {
            onStopMediaStream();
        }
    }

    zoomCamera(val) {
        const videoTrack = this.state.mediaStream?.getVideoTracks()[0];
        videoTrack.applyConstraints({advanced: [ {zoom: val} ]});
    }

    render() {


        const { captureButtonRenderer, responsive } = this.props;
        const { error, hasTorch } = this.state;

        if (error) {
            return <CameraError errorType={error} />;
        }

        return (
            <CameraWrapper>
                <video
                    autoPlay
                    playsInline
                    ref={video => (this.video = video)}
                    style={
                        responsive
                            ? { background: 'black', display: 'block', width: '100%' }
                            : { background: 'black', display: 'block' }
                    }
                />
                <CameraControls>
                    {captureButtonRenderer ? (
                        captureButtonRenderer(this.captureMediaStream)
                    ) : (
                        <CaptureButton onCapture={this.captureMediaStream} />
                    )}
                    <input type="range" id='zoomSlide' onInput={(e) => this.zoomCamera(e.target.value)} style={{display: 'none', width: '100%', margin: '1.5rem'}}/>
                </CameraControls>
                {hasTorch && (
                    <TorchButton
                        onTorchOn={this.torchOn}
                    />
                )}
            </CameraWrapper>
        );
    }
}

Camera.defaultProps = {
    facingMode: facingModes.ENVIRONMENT,
    responsive: true,
};

Camera.propTypes = {
    captureButtonRenderer: PropTypes.func,
    facingMode: PropTypes.string,
    height: PropTypes.number,
    onStopMediaStream: PropTypes.func,
    onTakePhoto: PropTypes.func,
    responsive: PropTypes.bool,
    width: PropTypes.number,
};

export default Camera;
