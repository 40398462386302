import React, { useState, useEffect, useRef, useCallback } from "react";
import Login, { WmFuncLogin } from "./Login";
import TaskList from "./TaskList";
import WmNavBar from "./WmNavBar";
import { userStatus, useInterval, CheckConnection, setMessageBoxInstance, showConfirmationDialog } from "./WmUtils";
import MessageBox from "../shared/MessageBox";
import { ToastContainer, toast } from "react-toastify";
import "../../node_modules/bootstrap/scss/bootstrap.scss";
import "react-toastify/dist/ReactToastify.css";
import * as types from "../shared/types";
import ConfirmationModal from "./ConfirmationDialog";
import useDialogStore from "../stores/useDialogStore";

function App() {
  const [fakeLogout, SetFakeLogout] = useState(false);
  const [activeSelection, SetActiveSelection] = useState(null);
  const [comStat, SetComStat] = useState(userStatus.undefined);

  const prevSelectionRef = useRef();
  const msgBoxRef = useRef();

  const prevSelection = prevSelectionRef.current;

  const { showModal, modalProps } = useDialogStore();

  useEffect(() => {
    prevSelectionRef.current = activeSelection;
  }, [activeSelection]);

  useInterval(async () => {
    const cckConRes = await CheckConnection();
    SetComStat(cckConRes);
  }, 15000);

  useEffect(() => {
    setMessageBoxInstance(msgBoxRef.current);
    (async function () {
      const cckConRes = await CheckConnection();
      SetComStat(cckConRes);
    })();
  }, []);

  useEffect(() => {
    console.log("COMSTAT##################");
    console.log(comStat.name);
    switch (comStat) {
      case userStatus.offline:
        toast.info(`Jelenleg az app offline állapotban van.`);
        break;
      case userStatus.logged_out:
        SetActiveSelection(null);
        if (fakeLogout) {
          SetFakeLogout(false);
        } else {
          if (localStorage.getItem("wm_auth_user")) {
            toast.success(`${localStorage.getItem("wm_auth_user")} felhasználó sikeresen kijelentkezett`);

            localStorage.removeItem("wm_auth_user");
          }

          if (localStorage.getItem("wm_auth_token")) {
            localStorage.removeItem("wm_auth_token");
          }
        }
        break;
      case userStatus.nack:
        SetActiveSelection(null);
        toast.error(`Rossz felhasználónév, vagy jelszó`);
        localStorage.removeItem("wm_auth_token");
        localStorage.removeItem("wm_auth_user");
        SetFakeLogout(true);
        SetComStat(userStatus.logged_out);
        break;
      case userStatus.unauthorized:
        SetActiveSelection(null);
        if (localStorage.getItem("wm_auth_user")) {
          toast.error(`${localStorage.getItem("wm_auth_user")} felhasználó nem rendelkezik a megfelelő jogosultságokkal.
                                Kérjen munkaadójától jogosultságot az app használatához.`);
          localStorage.removeItem("wm_auth_token");
        }
        break;
      case userStatus.ok:
        SetActiveSelection("Worklist");
        if (localStorage.getItem("wm_auth_user")) {
          toast.success(`${localStorage.getItem("wm_auth_user")} felhasználó sikeresen bejelentkezett`);
        } else {
          SetComStat(userStatus.logged_out);
          localStorage.removeItem("wm_auth_user");
          localStorage.removeItem("wm_auth_token");
        }
        break;
      default:
        break;
    }
  }, [comStat, fakeLogout]);

  const ComStatCallBack = useCallback(
    async (param) => {
      SetComStat(param);
    },
    [] // Tells React to memoize regardless of arguments.
  );

  const onLogOut = (result) => {
    if (result === types.MESSAGEBOX_RESPONSE_YES) {
      SetComStat(userStatus.logged_out);
      SetActiveSelection("Worklist");
    }
  };
  const selectionCallBack = (eventKey) => {
    switch (eventKey) {
      case "Logout":
        showConfirmationDialog("Biztos, hogy ki akar jelentkezni? A nem mentett adatok elvesznek.", "Kijelentkezés", (res) => onLogOut(res));
        break;
      default:
        SetActiveSelection(eventKey);
        break;
    }
  };

  return (
    <div className="App">
      <MessageBox ref={msgBoxRef} />
      <WmNavBar selectionCallBack={selectionCallBack} activeSelection={activeSelection} comStat={comStat} />
      <header className="App-header">
        {activeSelection === "Login" ? (
          <WmFuncLogin selectionCallBack={selectionCallBack} prevSelection={prevSelection} SetComStat={ComStatCallBack} />
        ) : (
          <>
            {activeSelection === "Worklist" && comStat !== userStatus.logged_out ? <TaskList comStat={comStat} SetComStat={ComStatCallBack} /> : null}
            {comStat === userStatus.logged_out && <Login selectionCallBack={selectionCallBack} SetComStat={ComStatCallBack} comStat={comStat} />}
          </>
        )}
      </header>
      <body>
        <ToastContainer />
        <ConfirmationModal
          show={showModal}
          title={modalProps.title}
          confirmation={modalProps.confirmation}
          okLabel={modalProps.okLabel}
          cancelLabel={modalProps.cancelLabel}
          onConfirm={modalProps.onConfirm}
          onCancel={modalProps.onCancel}
          enableEscape={modalProps.enableEscape}
        />
      </body>
    </div>
  );
}
export default App;
