import React from 'react';
import PropTypes from 'prop-types';

const TorchIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      strokeWidth="2"
    >
      <g strokeWidth="2" transform="translate(0, 0)">
        <polygon
          fill="none"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="square"
          strokeMiterlimit="10"
          points="19,17 15,8 19,1 5,7 9,15 4,23 "
          strokeLinejoin="miter"
        />{' '}

      </g>
    </svg>
  );

const torchButtonStyle = {
  alignItems: 'center',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  position: 'absolute',
  right: '5vw',
  top: '5vh',
  width: '40px',
};

const TorchButton = ({onTorchOn}) => (
  <button
    onClick={() =>
      onTorchOn()
    }
    style={torchButtonStyle}
    type="button"
  >
    <TorchIcon />
  </button>
);

TorchButton.propTypes = {
  onTorchOn: PropTypes.func.isRequired,
};

export default TorchButton;
