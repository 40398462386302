import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import { FaFileUpload } from "react-icons/fa";
import useOMEContentStore from "../stores/useOMEContentStore.jsx";
import { showBackendError } from "./ShowError";
import { toast } from "react-toastify";
import * as types from "../shared/types";
import { showConfirmationDialog } from "./WmUtils";
import useTaskDetailsFormControlStore from "../stores/TaskDetailsFormControlStore";

export const OMEFileButton = ({ actTask, disallowOMELoad, waterMeterList }) => {
  const setContent = useOMEContentStore((state) => state.setContent);
  const { isButtonDisabled } = useTaskDetailsFormControlStore((state) => ({
    isButtonDisabled: state.isButtonDisabled,
  }));
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      event.target.value = "";

      reader.onload = (e) => {
        try {
          let content = JSON.parse(e.target.result);
          content.task_id = actTask;
          setContent(content);
          toast.success("OME fájl sikeresen betöltve");
        } catch (error) {
          showBackendError("Az OME fájl betöltése nem sikerült!");
          console.error("Error parsing OME file", error);
        }
      };
    }
  };

  const onClick = () => {
    if (disallowOMELoad) {
      // showConfirmationDialog("Már vannak vízórák mentve. Nem tölthet be úgy vízórákat!", "Vízórák felvétele", (res) => {})
      toast.warning("Már vannak vízórák mentve. Nem tölthet be új vízórákat!");
    } else {
      if (waterMeterList?.length > 0) {
        showConfirmationDialog("Már vannak vízórák felvéve. Biztos, hogy felül szeretné írni őket?", "Vízórák felvétele", (res) => {
          if (res === types.MESSAGEBOX_RESPONSE_YES) {
            fileInputRef.current.click();
          }
        });
      } else {
        fileInputRef.current.click();
      }
    }
  };

  return (
    <div>
      <input type="file" ref={fileInputRef} style={{ display: "none" }} accept=".json" onChange={handleFileChange} />
      <Button onClick={() => onClick()} disabled={isButtonDisabled}>
        <h5>
          <FaFileUpload />
        </h5>
      </Button>
    </div>
  );
};
