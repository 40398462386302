
import React, { useState, useRef, useEffect, useCallback} from 'react';
import Form from 'react-bootstrap/Form';
import { WmPhotoBrief } from '../app/TakePhoto';
import ImageViewer from 'react-simple-image-viewer';
import Button from 'react-bootstrap/Button';
import * as types from "../shared/types";
import useTaskDetailsFormControlStore from '../stores/TaskDetailsFormControlStore';

const WorkSheetPhoto = props => {
    const [propState, setPropState] = useState(props);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const noteRef = useRef();
    const isMountedComponent = useRef(true);
    const propStateListActTaskItem = useRef(props.otherWorkList[`${props.actTask}`].wmlist[props.item]);
    const {isButtonDisabled} = useTaskDetailsFormControlStore((state) => ({
        isButtonDisabled: state.isButtonDisabled
    }));

    useEffect(() => {
        setPropState(props);
    }, [props]);


    useEffect(() => {

        if (isMountedComponent.current) {
            if (propState?.otherWorkList[`${propState.actTask}`]?.wmlist[propState.item]) {

                let tempInvItem = Object.assign({}, propState.otherWorkList);
                let tmpInvWmListItem = tempInvItem[`${propState.actTask}`].wmlist[propState.item];
                tmpInvWmListItem.description = `Munkalap ${new Date().toLocaleString('hu', { timeZone: 'Europe/Budapest' })}`;
                tmpInvWmListItem.price = types.INVOICE_ITEM_EXTREM_PRICE;

                propState.SetOtherWorkList(tempInvItem);


                propStateListActTaskItem.current = propState.otherWorkList[`${propState.actTask}`].wmlist[propState.item];
            }
        }
        return () => {
            isMountedComponent.current = false;
        }
    }, [propState]);


    const onThumbnailClick = index => {
        openImageViewer(index)
    }

    const onOtherThDelClick = (workitemIdx, imageIdx) => {
        const tempState = { ...propState.otherWorkList };
        tempState[`${propState.actTask}`].wmlist[workitemIdx].images.splice(imageIdx, 1);
        propState.SetOtherWorkList(tempState);
    }

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };


    const onInputChange = async (event) => {
        event.preventDefault();
        let tempInvItem = Object.assign({}, propState.otherWorkList);
        let tmpInvWmListItem = tempInvItem[`${propState.actTask}`].wmlist[propState.item];
        tmpInvWmListItem.note = noteRef.current.value;

        propState.SetOtherWorkList(tempInvItem);

    }
    if (!!propState && (propState.item !== null)) {
        return (<>
            <Form /* onSubmit={onFormSubmit} */>
                <Form.Group controlId="wpnote">
                    <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Megjegyzés:</small></Form.Label>
                    <Form.Control as="textarea" rows="3" placeholder="Megjegyzés" onBlur={onInputChange} defaultValue={propStateListActTaskItem.current.note} name="note" ref={noteRef} disabled={isButtonDisabled}/>
                </Form.Group>
            </Form>
            <WmPhotoBrief
                taskList={propState.taskList}
                anyWorkList={propState.otherWorkList}
                SetAnyWorkList={propState.SetOtherWorkList}
                actTask={propState.actTask}
                item={propState.item}
                file_name_ext='i'
            />
            <>
                                    <div  style={{ columns: "2 auto" }}>
                                        {propStateListActTaskItem.current.images.map((imgItem, idx) => (


                                               <div  key={idx}>
                                                    <img src={imgItem.image} alt="Wm-app kép" width="100%" onClick={() => onThumbnailClick(idx)} /><br />
                                                    <Button key="otherWlImgDel" variant="danger" onClick={() => onOtherThDelClick(propState.item, idx)} className="py-0 mt-1 mb-2" disabled={isButtonDisabled}>Kép törlése</Button></div>

                                        ))} </div>
                                        {isViewerOpen && (
                                            <ImageViewer
                                            src={propStateListActTaskItem.current.images.map(imgRec=>(
                                                imgRec.image
                                            ))}
                                                currentIndex={currentImage}
                                                onClose={closeImageViewer}
                                            />
                                        )}
                                   </>
        </>

        );
    } else {
        return (<div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            Munkalap foto...
        </div>);
    }
}
export default WorkSheetPhoto;
