import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as types from "./types";


class MessageBox extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      message: "",
      title: "",
      callbackFunction: {}
    }
  }
  setShow = (value) => {
    this.setState({
      show: value
    })
  }


  handleClose = (result) => {
    this.state.callbackFunction(result);
    return this.setState({
      show: false,
      message: "",
      title: "",
      callbackFunction: null
    })
  }

  handleShow = () => this.setShow(true);

  showMessage = (message, title, callbackFunction) => {
    return this.setState({
      show: true,
      message: message,
      title: title,
      callbackFunction: callbackFunction,

    })
  }

  render() {
    return (

        <Modal
          show={this.state.show}
          onHide={() => this.handleClose(types.MESSAGEBOX_RESPONSE_CANCELLED)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
    <Modal.Title>{this.state.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.message}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleClose(types.MESSAGEBOX_RESPONSE_YES)}>
              Igen
            </Button>
            <Button variant="primary" onClick={() => this.handleClose(types.MESSAGEBOX_RESPONSE_NO)}>
              Nem
            </Button>
          </Modal.Footer>
        </Modal>

    )
  }
}




export default MessageBox;
