import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import BarcodeScanner from "../barcode/BarcodeScanner";
import { FaQrcode } from "react-icons/fa";
import ReactModal from "react-modal";
import { LookUpFormField } from "./LookUpFormField";
import useTaskDetailsFormControlStore from '../stores/TaskDetailsFormControlStore';
import { formatSealWatermeterId } from "../app/WmUtils";

const WmeterForm = (props) => {
  const [propState, setPropState] = useState(props);
  const [barCodeRes, setBarCodeRes] = useState(null);
  const [barcodeScannerOn, setBarcodeScannerOn] = useState(false);
  const [lookupFieldReload, setLookupFieldReload] = useState(null);
  const [localPhysicalPlace, setLocalPhysicalPlace] = useState(propState.defaultPhysicalPlace || "");
  const cold_hotRef = useRef();
  const placeRef = useRef();
  const sizeRef = useRef();
  const okRef = useRef();
  const meterRef = useRef();
  const idRef = useRef();
  const p1idRef = useRef();
  const p1okRef = useRef();
  const p2idRef = useRef();
  const p2okRef = useRef();
  const noteRef = useRef();

  const {isButtonDisabled} = useTaskDetailsFormControlStore((state) => ({
    isButtonDisabled: state.isButtonDisabled
}));

  const propStateListWmItem = props.wmWorkList[`${props.actTask}`].wmlist[props.item];
  propStateListWmItem.current=props.wmWorkList[`${props.actTask}`].wmlist[props.item];

  useEffect(() => {
    setLocalPhysicalPlace(propState.defaultPhysicalPlace);
  },[propState.defaultPhysicalPlace]);

  useEffect(() => {
    const primaryWatermeterLocation  = !!propStateListWmItem.current.watermeter_physical_place
    ? propStateListWmItem.current.watermeter_physical_place
    : propState.taskList.find(
        (x) => x.task_id === propState.actTask
      ).work_watermeter_physical_place;
    setLocalPhysicalPlace(primaryWatermeterLocation );
  },[]);

  useEffect(() => {
    setPropState(props);
  }, [props]);

  useEffect(() => {
      propStateListWmItem.current = propState.wmWorkList[`${propState.actTask}`].wmlist[propState.item];
  }, [propState]);

  /*eslint-disable eqeqeq*/
  const onInputChange = async (event) => {
    event?.preventDefault();
    let tempInvItem = Object.assign({}, propState.wmWorkList);
    const actInvItemListItem =
      tempInvItem[`${propState.actTask}`].wmlist[propState.item];
    actInvItemListItem.is_cold = cold_hotRef.current.value;
    actInvItemListItem.watermeter_physical_place = placeRef.current.value;
    actInvItemListItem.size = sizeRef.current.value;
    actInvItemListItem.meter_ok = okRef.current.value;
    actInvItemListItem.act_meter = meterRef.current.value;
    if (!!idRef?.current?.value)
      actInvItemListItem.meter_identifier = idRef.current.value;
    if (!!p1idRef?.current?.value)
      actInvItemListItem.plomba1_identifier = p1idRef.current.value;
    actInvItemListItem.plomba1_ok = p1okRef.current.value;
    if (!!p2idRef?.current?.value) {
      actInvItemListItem.plomba2_identifier =
        p2idRef.current.value == "---" ? null : p2idRef.current.value;
    }
    actInvItemListItem.plomba2_ok = p2okRef.current.value;
    actInvItemListItem.note = noteRef.current.value;

    propState.SetWmWorkList(tempInvItem);
  };
  useEffect(() => {
    if (!!barCodeRes) {
      const barcode = formatSealWatermeterId(barCodeRes);
      let tempInvItem = Object.assign({}, propState.wmWorkList);

      switch (barcodeScannerOn) {
        case idRef:
          tempInvItem[`${propState.actTask}`].wmlist[
            propState.item
          ].meter_identifier = barcode;
          if (idRef?.current) idRef.current.value = barcode;
          break;
        case p1idRef:
          tempInvItem[`${propState.actTask}`].wmlist[
            propState.item
          ].plomba1_identifier = barcode;
          if (p1idRef?.current) p1idRef.current.value = barcode;
          break;
        case p2idRef:
          tempInvItem[`${propState.actTask}`].wmlist[
            propState.item
          ].plomba2_identifier = barcode;
          if (p2idRef?.current) p2idRef.current.value = barcode;
          break;

        default:
          break;
      }
      propState.SetWmWorkList(tempInvItem);
      setBarcodeScannerOn(null);
      setBarCodeRes(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barCodeRes]);

  return (
    <>
      <ReactModal
        key={propState.keyProp + "barcmod"}
        isOpen={!!barcodeScannerOn}
        appElement={document.getElementById("root")}
        style={{
          width: "100%",
          height: "100%",
          margin: "0",
          padding: "0",
          dialog: {
            width: "100%",
            height: "100%",
            margin: "0",
            padding: "0",
          },
          content: {
            width: "100%",
            height: "auto",
            minHeight: "100%",
            inset: "0",
            borderRadius: "0",
            margin: "0",
            padding: "0",
          },
        }}
        contentLabel="Barcode Modal"
      >
        <BarcodeScanner
          setBarCodeRes={(bcRs) => {
            setBarCodeRes(bcRs); /*  wmBcode.exit();  */
          }}
          key={propState.keyProp + "barcrdr"}
        />
      </ReactModal>
      <Form key={propState.keyProp + "wmform"}>
        <Form.Group controlId="cold_hot">
          <Form.Label className="my-1 mr-2"
           key={propState.keyProp + "focus"}
           ref={(focref) => {
             if (!!focref)
               propState.focusRefList.current[propState.keyProp + "focus"] =
                 focref;
           }}>
            <small style={{ color: "indigo", textAlign: "left" }}>
              Vízmérő hideg/meleg:
            </small>
          </Form.Label>
          <Form.Control
            disabled={isButtonDisabled}
            onBlur={onInputChange}
            as="select"
            className="my-1 mr-sm-2"
            custom
            ref={cold_hotRef}
            defaultValue={propStateListWmItem.current.is_cold}
          >
            <option value={null}>Válassz...</option>
            <option value={false}>Meleg</option>
            <option value={true}>Hideg</option>
          </Form.Control>{" "}
        </Form.Group>
        <Form.Group controlId="phys">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>
              Készülékhely/felhasználási hely:
            </small>
          </Form.Label>
          <Form.Control
            disabled={isButtonDisabled}
            placeholder="Vízmérő helye"
            defaultValue={localPhysicalPlace}
            onBlur={onInputChange}
            onChange={(e) => {
              propState.setDefaultPhysicalPlaces(e.target.value);
            }}
            name="note"
            ref={placeRef}
          />
        </Form.Group>
        <Form.Group controlId="size">
          <Form.Label className="my-1 mr-2">
            <small style={{ color: "indigo", textAlign: "left" }}>
              Vízmérő mérete:
            </small>
          </Form.Label>
          <Form.Control
            disabled={isButtonDisabled}
            onBlur={onInputChange}
            as="select"
            className="my-1 mr-sm-2"
            custom
            ref={sizeRef}
            defaultValue={propStateListWmItem.current.size}
          >
            <option value={null}>Válassz...</option>
            <option value={13}>13</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
            <option value={32}>32</option>
          </Form.Control>{" "}
        </Form.Group>
        <Form.Group controlId="meterok">
          <Form.Label className="my-1 mr-2">
            <small style={{ color: "indigo", textAlign: "left" }}>
              {" "}
              Vízmérő állapota:
            </small>
          </Form.Label>
          <Form.Control
            disabled={isButtonDisabled}
            onBlur={onInputChange}
            as="select"
            className="my-1 mr-sm-2"
            custom
            ref={okRef}
            defaultValue={propStateListWmItem.current.meter_ok}
          >
            <option value={null}>Válassz...</option>
            <option value={true}>Ép</option>
            <option value={false}>Sérült</option>
          </Form.Control>{" "}
        </Form.Group>
        <Form.Group controlId="meter">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>
              Mérőállás:
            </small>
          </Form.Label>
          <Form.Control
            disabled={isButtonDisabled}
            type="number"
            placeholder="Mérő állása"
            onBlur={onInputChange}
            defaultValue={propStateListWmItem.current.act_meter}
            name="meter"
            ref={meterRef}
          />
        </Form.Group>
        <Form.Group controlId="wm_id">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>
              Gyáriszám:
            </small>
          </Form.Label>
          <Form.Row className="align-items-center ">
            <Col className="px-0">
              <LookUpFormField
                placeholder="Gyáriszám"
                onBlur={onInputChange}
                defaultValue={propStateListWmItem.current.meter_identifier}
                name="wmID"
                parentRef={idRef}
                optDataList={propState.wmList}
                wmItem={propState.item}
                wmListOfTask={propState.wmWorkList[`${props.actTask}`].wmlist}
                lookupFieldReload={lookupFieldReload}
                setLookupFieldReload={setLookupFieldReload}
                disabled={!!propStateListWmItem.current.id || isButtonDisabled}
              />
            </Col>
            <Col xs="auto">
              <Button
                disabled={!!propStateListWmItem.current.id || isButtonDisabled}
                className="px-1 mx-0 py-0 "
                variant="outline-dark"
                onClick={() => {
                  /* wmBcode.enter();  */ setBarcodeScannerOn(idRef);
                }}
              >
                <h5>
                  <FaQrcode />
                </h5>
              </Button>
            </Col>
          </Form.Row>
        </Form.Group>

        <Form.Group controlId="p1_id">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>
              Biztonsági záróelem-1 száma:
            </small>
          </Form.Label>
          <Form.Row className="align-items-center">
            <Col className="px-0">
              <LookUpFormField
                placeholder="Biztonsági záróelem-1 száma"
                onBlur={onInputChange}
                defaultValue={propStateListWmItem.current.plomba1_identifier}
                name="seal1ID"
                parentRef={p1idRef}
                optDataList={propState.sealList}
                wmItem={propState.item}
                wmListOfTask={propState.wmWorkList[`${props.actTask}`].wmlist}
                lookupFieldReload={lookupFieldReload}
                setLookupFieldReload={setLookupFieldReload}
                disabled={!!propStateListWmItem.current.id || isButtonDisabled}
              />
            </Col>
            <Col xs="auto">
              <Button
                disabled={!!propStateListWmItem.current.id || isButtonDisabled}
                className="px-1 mx-0 py-0 "
                variant="outline-dark"
                onClick={() => {
                  /*  wmBcode.enter();  */ setBarcodeScannerOn(p1idRef);
                }}
              >
                <h5>
                  <FaQrcode />
                </h5>
              </Button>
            </Col>
          </Form.Row>
        </Form.Group>
        <Form.Group controlId="p1ok">
          <Form.Label className="my-1 mr-2">
            <small style={{ color: "indigo", textAlign: "left" }}>
              Záróelem-1 állapota:
            </small>
          </Form.Label>
          <Form.Control
            disabled={isButtonDisabled}
            onBlur={onInputChange}
            as="select"
            className="my-1 mr-sm-2"
            custom
            ref={p1okRef}
            defaultValue={propStateListWmItem.current.plomba1_ok}
          >
            <option value={null}>Válassz...</option>
            <option value={true}>Ép</option>
            <option value={false}>Sérült</option>
          </Form.Control>{" "}
        </Form.Group>
        <Form.Group controlId="p2_id">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>
              Biztonsági záróelem-2 száma:
            </small>
          </Form.Label>
          <Form.Row className="align-items-center">
            <Col className="px-0">
              <LookUpFormField
                placeholder="Biztonsági záróelem-2 száma"
                onBlur={onInputChange}
                defaultValue={propStateListWmItem.current.plomba2_identifier}
                name="seal2ID"
                parentRef={p2idRef}
                optDataList={propState.sealList}
                wmItem={propState.item}
                wmListOfTask={propState.wmWorkList[`${props.actTask}`].wmlist}
                lookupFieldReload={lookupFieldReload}
                setLookupFieldReload={setLookupFieldReload}
                disabled={!!propStateListWmItem.current.id || isButtonDisabled}
              />
            </Col>
            <Col xs="auto">
              <Button
                disabled={!!propStateListWmItem.current.id || isButtonDisabled}
                className="px-1 mx-0 py-0 "
                variant="outline-dark"
                onClick={() => {
                  /* wmBcode.enter(); */ setBarcodeScannerOn(p2idRef);
                }}
              >
                <h5>
                  <FaQrcode />
                </h5>
              </Button>
            </Col>
          </Form.Row>
        </Form.Group>
        <Form.Group controlId="p1ok">
          <Form.Label className="my-1 mr-2">
            <small style={{ color: "indigo", textAlign: "left" }}>
              Záróelem-2 állapota:
            </small>
          </Form.Label>
          <Form.Control
            disabled={isButtonDisabled}
            onBlur={onInputChange}
            as="select"
            className="my-1 mr-sm-2"
            custom
            ref={p2okRef}
            defaultValue={propStateListWmItem.current.plomba2_ok}
          >
            <option value={null}>Válassz...</option>
            <option value={true}>Ép</option>
            <option value={false}>Sérült</option>
          </Form.Control>{" "}
        </Form.Group>
        <Form.Group controlId="note">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>
            Szerelő megjegyzése az ügyfél felé:
            </small>
          </Form.Label>
          <Form.Control
            disabled={isButtonDisabled}
            as="textarea" rows="3"
            placeholder="Szerelő megjegyzése az ügyfél felé"
            onBlur={onInputChange}
            defaultValue={propStateListWmItem.current.note}
            name="note"
            ref={noteRef}
          />
        </Form.Group>
      </Form>
    </>
  );
};
export default WmeterForm;
