import React from "react";
import { Blocks } from "react-loader-spinner";

export const ButtonLoader = () => {
  return (<Blocks
    width="25"
    height="25"
    color="indigo"
    ariaLabel="blocks-loading"
    wrapperStyle={{}}
    wrapperClass="blocks-wrapper" />);
}
