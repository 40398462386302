import React, {useState, useEffect} from 'react';
import BarcodeScannerComponent from "./BarcodeComponent";

const BarcodeScanner = props => {
    const [propState, setPropState] = useState(props);

    useEffect(() => {
        setPropState(props);
    }, [props]);

    return (
        <BarcodeScannerComponent
          width={300}
          height={300}
          onUpdate={(err, result) => {
            if (result){
                 propState.setBarCodeRes(result.text);
            }
            else{
                    propState.setBarCodeRes('Nem található olvasható vonalkód');
            }
          }}
        />
    )
  }

  export default BarcodeScanner;
