
import { useState, useRef, useEffect, useCallback } from 'react';
//import Form from 'react-bootstrap/Form';
import CreatableSelect from 'react-select/creatable';
import * as types from '../shared/types';
import { formatSealWatermeterId } from '../app/WmUtils';

/*eslint-disable eqeqeq*/
export const LookUpFormField = props => {
    const createOption = (label) => (!!label ? {
        label: label,
        value: label
    } : { label: "", value: "" });
    const optName = useRef();


    const [propState, setPropState] = useState(props);
    const [warnColor, setWarnColor] = useState(false);
    const [optListStat, setOptListStat] = useState();
    const [creatableValue, setCreatableValue] = useState(null);
    const [inputFieldValue, setInputFieldValue] = useState('');
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const isMountedComponent = useRef(true);
    const emptyArr = useRef([{ label: "üres", value: "---" }]);

    const findSameID = currentIDValue => (optName.current == types.PREFIX_WM
        ?
        propState.wmListOfTask.filter((_, idx) => (idx != propState.wmItem)).findIndex(wmii => {
            if (wmii.meter_identifier == currentIDValue) {
                return true;
            }

            return false;
        })
        :
        propState.wmListOfTask.findIndex((wmii, iii) => {
            if (iii !== propState.wmItem) {
                if ((wmii.plomba1_identifier == currentIDValue) || (wmii.plomba2_identifier == currentIDValue)) {
                    return true;
                }
            } else {
                if (propState.name == "seal1ID") {
                    if (wmii.plomba2_identifier == currentIDValue) {
                        return true;
                    }
                } else {
                    if (wmii.plomba1_identifier == currentIDValue) {
                        return true;
                    }
                }
            }

            return false;
        }));

    const setRedding = () => {
        if (!creatableValue)
            return;
        const currentIDValue = creatableValue?.value;
        if (props.name == 'seal2ID' && creatableValue.value == '---') {
            if (!!warnColor) {
                setWarnColor(false);
            }
            return;
        }

        if (!!currentIDValue) {
            let isRedding = false;
            if (props?.optDataList.current?.length > 0) {
                let currentSelection = propState.optDataList.current.find(x => x[optName.current] == currentIDValue);
                if (!!currentSelection) {
                    const foundSameID = findSameID(currentIDValue);

                    if (!!(foundSameID > -1)) {
                        isRedding = true;
                    }
                } else {

                    isRedding = true;
                }
            } else {

                isRedding = true;
            }
            setWarnColor(isRedding);

            forceUpdate();

        }
    }

    useEffect(() => {
        setPropState(props);
        if (props?.optDataList.current?.length > 0) {
            setOptListStat([...emptyArr.current,
            ...props?.optDataList.current?.filter(oii => (oii.duty === null))
                .map(oii => createOption(oii[optName.current]?.toString()))]);
        } else {
            if (optListStat != emptyArr.current)
                setOptListStat(emptyArr.current);
        }

        if (isMountedComponent.current) {

            emptyArr.current = props.name == 'seal2ID' ? [{ label: "üres", value: "---" }] : [];
            optName.current = props?.optDataList.current?.[0]?.hasOwnProperty(types.PREFIX_WM) ? types.PREFIX_WM : types.PREFIX_SEAL;
            setCreatableValue(createOption(props.defaultValue));
            if (props?.parentRef?.current) {
                props.parentRef.current.value = props.defaultValue;
            }
        }
        return () => {
            isMountedComponent.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        if (propState?.optDataList.current?.length > 0) {
            setOptListStat([...emptyArr.current,
            ...propState?.optDataList.current?.filter(oii => (oii.duty === null))
                .map(oii => createOption(oii[optName.current]?.toString()))]);
        } else {

            if (optListStat != emptyArr.current)
                setOptListStat(emptyArr.current);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propState.optDataList]);

    useEffect(() => {
        setRedding();

        propState.setLookupFieldReload(propState.name)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [creatableValue]);

    useEffect(() => {
        let actualIDValue = undefined;
        if (!!(propState?.parentRef?.current?.value?.length > 0)) {
            actualIDValue = propState.parentRef.current.value;
        } else if (!!(propState.defaultValue?.length > 0)) {
            actualIDValue = propState.defaultValue;
        }

        if (!!(propState?.optDataList.current?.length > 0)) {
            if (!!actualIDValue && !!creatableValue  &&(creatableValue.value !==null) && (actualIDValue != creatableValue?.value)) {
                let prevSelection = propState.optDataList.current.find(x => x[optName.current] == creatableValue.value);
                if (!!prevSelection) {
                    const foundSameID = findSameID(creatableValue.value);
                    if (!(foundSameID > -1)) {
                        if (prevSelection.duty !== null) {
                            prevSelection.duty = null;

                            if (props?.optDataList.current?.length > 0)
                                setOptListStat([...emptyArr.current,
                                ...propState?.optDataList.current?.filter(oii => (oii.duty === null))
                                    .map(oii => createOption(oii[optName.current]?.toString()))]);
                        }
                    }
                }
            }

            if (!!actualIDValue && actualIDValue.length > 0) {
                let currSelection = propState.optDataList.current.find(x => x[optName.current] == actualIDValue);
                if (!!currSelection) {
                    if (currSelection.duty !== propState.wmItem) {
                        currSelection.duty = propState.wmItem;

                        if (props?.optDataList.current?.length > 0)
                            setOptListStat([...emptyArr.current,
                            ...propState?.optDataList.current?.filter(oii => (oii.duty === null))
                                .map(oii => createOption(oii[optName.current]?.toString()))]);
                    }
                }

                setCreatableValue(createOption(actualIDValue));
            }
        } else {
            if (optListStat != emptyArr.current)
                setOptListStat(emptyArr.current);
            if (!!actualIDValue && actualIDValue.length > 0) {
                setCreatableValue(createOption(actualIDValue));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optListStat, propState?.parentRef?.current?.value, propState.defaultValue]);

    useEffect(() => {
        if (!!propState.lookupFieldReload && (propState.lookupFieldReload != propState.name)) {
            setRedding();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propState.lookupFieldReload]);

    // Refers to on change event of the select field of the component.
    const onChange = (newOpt, action) => {
        if (propState?.parentRef?.current) {
            propState.parentRef.current.value = newOpt.value;
        }

    }

    // Refers to on input change event of the text field of the component.
    const handleInput = (e) => {
        // Apply regex to only allow numbers and no prefix zeros.
        setInputFieldValue(formatSealWatermeterId(e));
    }

    const onCreateNew = (newVal) => {
        if (propState?.parentRef?.current) {
            propState.parentRef.current.value = newVal;
        }


    }

    const colourWarn = theme => {
        const retVal = {
            ...theme,
            colors: { ...theme.colors }
        };
        retVal.colors.neutral0 = warnColor ? '#ff8080' : 'white';
        return retVal;
    }

    return (
        <>
            {!!creatableValue && <CreatableSelect
                onCreateOption={onCreateNew}
                value={creatableValue}
                onChange={onChange}
                onInputChange={handleInput}
                inputValue={inputFieldValue}
                options={optListStat}
                {...(({ parentRef, optDataList, wmItem, wmListOfTask, lookupFieldReload, setLookupFieldReload, disabled, ...obj }) => obj)(propState)}
                ref={propState.parentRef}
                theme={theme => (colourWarn(theme))}
                formatCreateLabel={txt => (`Új azonosító: "${txt}"`)}
                noOptionsMessage={() => "Nincs adat"}
                isDisabled={props.disabled}
            />}

        </>
    );
}
