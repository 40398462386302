import React, { useState,useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Button from 'react-bootstrap/Button';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import {
    userStatus, apiURL, GetImage, PostPhoto
} from './WmUtils';
import { showBackendError } from './ShowError';
import { backendErrorMessage } from '../shared/backendError';
import useTaskDetailsFormControlStore from '../stores/TaskDetailsFormControlStore';
import useSettingsStore from '../stores/SettingsStore';

const taskURL = apiURL + '/tasks';

export const SignatureBrief = (props) => {
    const [propState, setPropState] = useState(props);
    const actTask = useRef(propState.taskList.find(x => x.task_id === props.actTask));
    const worksheetsObject = actTask.current.task_worksheets;

    const {isButtonDisabled} = useTaskDetailsFormControlStore((state) => ({
        isButtonDisabled: state.isButtonDisabled
    }));
    const { settings } = useSettingsStore();

    const [show, setShow] = useState(false);
    const [links, setLinks] = useState(worksheetsObject);
    let sigPad = useRef({});

    useEffect(() => {
        setPropState(props);
    }, [props]);



    const save = async () => {
        setShow(false)

        const dataUrl = sigPad.current.toDataURL("image/png").split(';base64,')[1];
        let imgFileData = new FormData();
        imgFileData.append('sign', dataUrl);
        let signatures = await PostPhoto(`${apiURL}/tasks/${propState.actTask}/worksheetsignature/`, imgFileData);

        if(signatures.status === 200 || signatures.status === 201 ){
            toast.success('Ügyfél aláírás rögzítése sikerült.')
           if(links){
             setLinks(links=>[...links, signatures.data.worksheet]);
             actTask.current.task_worksheets = [...links, signatures.data.worksheet];
           }else{
             setLinks(links=> [signatures.data.worksheet]);
             actTask.current.task_worksheets = [signatures.data.worksheet];
           }
           downloadWorksheet(null, signatures.data.worksheet);
        }else {
            toast.error('Ügyfél aláírás rögzítése nem sikerült.')
        }

    }

    const downloadWorksheet = async (event, worksheet) => {
        if(event){
            event.preventDefault();
        }
        const openPdf =  await GetImage(taskURL + `/${propState.actTask}/worksheet/${worksheet}/`);
        if (openPdf?.status === 200) {
            const pdfUrl = URL.createObjectURL(openPdf.data);
            let link = document.createElement('a');
            link.href = pdfUrl;
            link.download =worksheet.toString();
            link.click();

        } else {
            if (openPdf?.status === 403) {
                propState.SetComStat(userStatus.unautorized);
            } else {
                propState.SetComStat(userStatus.offline);
            }
            showBackendError(backendErrorMessage("Hiba a munkalap letöltésekor: ", openPdf));
        }
    }


    return (
        <>
            {show &&
                <ReactModal
                    isOpen={show}
                    appElement={document.getElementById('root')}
                    style={{
                        width: '100%',
                        height: '100%',
                        margin: '0',
                        padding: '0',
                        dialog: {
                            width: '100%',
                            height: '100%',
                            margin: '0',
                            padding: '0'
                        },
                        content: {
                            width: '100%',
                            height: 'auto',
                            minHeight: '100%',
                            inset: '0',
                            borderRadius: '0',
                            margin: '0',
                            padding: '0'
                        }
                    }}
                >
                    <div>
                        <SignatureCanvas ref={sigPad} penColor="black" />
                        <div>
                            <Button variant="success" className='mx-2' onClick={save}>OK</Button>
                            <Button variant="danger" onClick={() => setShow(false)} className="mx-2">Mégse</Button>
                        </div>
                    </div>
                </ReactModal>
            }

            {settings?.features?.signature?.enabled &&
                <div>
                    <div style={{ textAlign: "center" }} className="pt-2"><h5 style={{ color: "aqua", textAlign: "center" }}>Digitálisan aláírt munkalap feltöltése</h5></div>
                    <ul>
                        {
                            links?.map(link =>{
                                return <li><a onClick={event=>downloadWorksheet(event, link)}>{link}</a></li>
                            })
                        }
                    </ul>
                    <Button variant="info" onClick={() => setShow(true)} className="ml-4 mb-2" disabled={isButtonDisabled}>
                        Ügyfél aláírás rögzítése
                    </Button>
                </div>
            }
        </>

    );
}
