import { create } from "zustand";

const useTaskDetailsFormControlStore = create((set) => ({
    isButtonDisabled: false,
    isButtonDisabledSave: false, //the state of the isButtonDisabled variable will be saved temporary (during loading)
    loading: false, //true, during any save operation caused by button click
    loadingTarget:  "", //during loading contains the id of the button clicked by the user (for loader/spinner purposes)
    setIsButtonDisabled: (value) => set((state) => ({ ...state,  isButtonDisabled: value, isButtonDisabledSave: value })),
    setLoading: (value) => set((state) => {
        if (value)
            return { ...state,  loading: value, isButtonDisabledSave: state.isButtonDisabled, isButtonDisabled: true};
        else
            return { ...state,  loading: value, isButtonDisabled: state.isButtonDisabledSave};
    }),
    setLoadingTarget: (value) => set((state) => ({ ...state,  loadingTarget: value })),
}));


export const SAVE_WORKSHEET = "SAVE_WORKSHEET";
export const SAVE_SIGNED_WORKSHEET = "SAVE_SIGNED_WORKSHEET";
export const SAVE_AND_SEND_WORKSHEET = "SAVE_AND_SEND_WORKSHEET";
export const SAVE_AND_NEXT_TO_INVOICING = "SAVE_AND_NEXT_TO_INVOICING";
export const SAVE_INVOICE_FORM = "SAVE_INVOICE_FORM";
export const SAVE_ADDRESS_FORM = "SAVE_ADDRESS_FORM";
export const SAVE_CONTROL_START = "SAVE_CONTROL_START";
export const SAVE_CONTROL_SUCCESS = "SAVE_CONTROL_SUCCESS";
export const SAVE_CONTROL_FAILED = "SAVE_CONTROL_FAILED";

export default useTaskDetailsFormControlStore;
