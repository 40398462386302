import React, { useState, useEffect, useRef} from 'react';

import Button from 'react-bootstrap/Button';
import Camera from '../camera/Camera';
import ReactModal from 'react-modal';
import useTaskDetailsFormControlStore from '../stores/TaskDetailsFormControlStore';



export const WmPhotoBrief = props => {
    const [propState, setPropState] = useState(props);
    const [show, setShow] = useState(false);

    const isMountedComponent = useRef(true);

    const isButtonDisabled = useTaskDetailsFormControlStore((state) => state.isButtonDisabled);

    useEffect(() => {
        if (isMountedComponent.current) {
            setPropState(props);
        }
        return () => {
             isMountedComponent.current = false; };
    }, [props]);


    const handleTakePhotoBasic = async (img) => {
        const curTask = propState.taskList.find(x => x.task_id === propState.actTask);
        const newState = Object.assign({}, propState.anyWorkList);
        const newStateActTask = newState[`${propState.actTask}`];
        let curImgList = newStateActTask.wmlist[propState.item].images;
        const imgFileName = `${curTask.work_no}-${curTask.task_no}-${propState.file_name_ext}${newStateActTask.imgSerial.toString().padStart(3, "0")}.jpg`;
        newStateActTask.imgSerial ++;
        setShow(false);
        if (
           Array.isArray(curImgList) &&
           curImgList.length>0
           ){
            curImgList = [...curImgList,{"name":imgFileName, "image":img}];

        } else {
            curImgList =  [{"name":imgFileName, "image":img}];
        }
        newStateActTask.wmlist[propState.item].images = curImgList;

        propState.SetAnyWorkList(newState);
        let link = document.createElement('a');
        link.href = img;
        link.download = imgFileName;
        link.click();

    };

    return (
        <>
        {show && <ReactModal
            isOpen={show}
            appElement={document.getElementById('root')}
            style={{
                width: '100%',
                height: '100%',
                margin: '0',
                padding: '0',
                dialog: {
                    width: '100%',
                    height: '100%',
                    margin: '0',
                    padding: '0'
                },
                content: {
                    width: '100%',
                    height: 'auto',
                    minHeight: '100%',
                    inset: '0',
                    borderRadius: '0',
                    margin: '0',
                    padding: '0'
                }
                // transform: 'translate(-50%, -50%)'
            }}
            contentLabel="Photo Modal"
        >
            <div>
                <div>
                    <Camera onTakePhoto={handleTakePhotoBasic} />
                </div>
                <Button variant="success" onClick={() => setShow(false)} className="mt-1 mb-2 ">Kamera kikapcsol</Button>
            </div>
        </ReactModal>}
        <Button variant="info" onClick={() => setShow(true)} className="ml-4 mb-2" disabled={isButtonDisabled}>
            Fotózás
        </Button>
        </>
    );
}
