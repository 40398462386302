import { create } from "zustand";

const useDialogStore = create((set) => ({
  showModal: false,
  modalProps: {},
  openModal: (props) => set({ showModal: true, modalProps: props }),
  closeModal: () => set({ showModal: false, modalProps: {} }),
}));

export default useDialogStore;
