import React, { useState, useRef, forwardRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import useTaskDetailsFormControlStore, { SAVE_INVOICE_FORM } from "../stores/TaskDetailsFormControlStore";
import { ButtonLoader } from "./ButtonLoader.jsx"

const WmInvoiceForm = forwardRef((props, ref) => {
  const [propState, setPropState] = useState(props);

  const nameRef = useRef();
  const countryRef = useRef();
  const taxNoRef = useRef();
  const postalCodeRef = useRef();
  const cityRef = useRef();
  const routeRef = useRef();
  const strNoRef = useRef();

  const buildingNoRef = useRef();
  const floorRef = useRef();
  const doorRef = useRef();
  const doorBellNoRef = useRef();


  const { loading, loadingTarget } = useTaskDetailsFormControlStore((state) => ({
    loading: state.loading,
    loadingTarget: state.loadingTarget,
  }));

  const saveInvoiceData = () => {
    const actInvoiceData = {
      contact_name: nameRef.current.value,
      address_country: countryRef.current.value,
      tax_number: taxNoRef.current.value,
      address_postal_code: postalCodeRef.current.value,
      address_city: cityRef.current.value,
      address_route: routeRef.current.value,
      address_street_number: strNoRef.current.value,
      address_building: buildingNoRef.current.value,
      address_floor: floorRef.current.value,
      address_door: doorRef.current.value,
      address_doorbell: doorBellNoRef.current.value,
    };
    propState.setInvoiceData(actInvoiceData);
    propState.onSave(SAVE_INVOICE_FORM);
  };

  const onInputChange = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      propState.setInvoiceFormValidated(false);
    } else {
      propState.setInvoiceFormValidated(true);
    }
  };

  const backButtonClick = () => {
    propState.setOverAllAcrdActiveKey("2");
  };

  return (
    <>
      <Form /*onSubmit={onFormSubmit}*/>
        <Form.Group controlId="invoiceName">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>Név (cég v. magánszemély):</small>
          </Form.Label>
          <div key={propState.invoiceData.contact_name}>
            <Form.Control onBlur={onInputChange} placeholder="Név (cég v. magánszemély)" defaultValue={propState.invoiceData.contact_name} name="contactName" ref={nameRef} />
          </div>
        </Form.Group>
        <Form.Group controlId="invoiceCountry">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>Ország:</small>
          </Form.Label>
          <div key={propState.invoiceData.address_country}>
            <Form.Control onBlur={onInputChange} placeholder="Ország" defaultValue={propState.invoiceData.address_country} name="invcountry" ref={countryRef} />
          </div>
        </Form.Group>
        <Form.Group controlId="invoiceTaxNo">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>Adószám:</small>
          </Form.Label>
          <div key={propState.invoiceData.tax_number}>
            <Form.Control onBlur={onInputChange} placeholder="Adószám" defaultValue={propState.invoiceData.tax_number} name="invtaxno" ref={taxNoRef} />
          </div>
        </Form.Group>
        <Form.Group controlId="invoicePostalCode">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>Irányítószám:</small>
          </Form.Label>
          <div key={propState.invoiceData.address_postal_code}>
            <Form.Control onBlur={onInputChange} placeholder="Irányítószám" defaultValue={propState.invoiceData.address_postal_code} name="invpostalcode" ref={postalCodeRef} />
          </div>
        </Form.Group>
        <Form.Group controlId="invoiceCity">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>Város:</small>
          </Form.Label>
          <div key={propState.invoiceData.address_city}>
            <Form.Control onBlur={onInputChange} placeholder="Város" name="invcity" defaultValue={propState.invoiceData.address_city} ref={cityRef} />
          </div>
        </Form.Group>
        <Form.Group controlId="InvoiceRoute">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>Közterület:</small>
          </Form.Label>
          <div key={propState.invoiceData.address_route}>
            <Form.Control onBlur={onInputChange} placeholder="Közterület" name="invroute" defaultValue={propState.invoiceData.address_route} ref={routeRef} />
          </div>
        </Form.Group>
        <Form.Group controlId="InvoiceStrNo">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>Szám:</small>
          </Form.Label>
          <div key={propState.invoiceData.address_street_number}>
            <Form.Control onBlur={onInputChange} placeholder="Szám" name="invstrno" defaultValue={propState.invoiceData.address_street_number} ref={strNoRef} />
          </div>
        </Form.Group>

        <Form.Group controlId="InvoiceBuilding">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>Épület:</small>
          </Form.Label>
          <div key={propState.invoiceData.address_building}>
            <Form.Control onBlur={onInputChange} placeholder="Épület" name="invbld" defaultValue={propState.invoiceData.address_building} ref={buildingNoRef} />
          </div>
        </Form.Group>
        <Form.Group controlId="InvoiceFloor">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>Emelet:</small>
          </Form.Label>
          <div key={propState.invoiceData.address_floor}>
            <Form.Control onBlur={onInputChange} placeholder="Emelet" name="invfloor" defaultValue={propState.invoiceData.address_floor} ref={floorRef} />
          </div>
        </Form.Group>
        <Form.Group controlId="InvoiceDoor">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>Ajtó:</small>
          </Form.Label>
          <div key={propState.invoiceData.address_door}>
            <Form.Control onBlur={onInputChange} placeholder="Ajtó" name="invdoor" defaultValue={propState.invoiceData.address_door} ref={doorRef} />
          </div>
        </Form.Group>
        <Form.Group controlId="InvoiceDoorBell">
          <Form.Label>
            <small style={{ color: "indigo", textAlign: "left" }}>Csengő:</small>
          </Form.Label>
          <div key={propState.invoiceData.address_doorbell}>
            <Form.Control onBlur={onInputChange} placeholder="Csengő" name="invbell" defaultValue={propState.invoiceData.address_doorbell} ref={doorBellNoRef} />
          </div>
        </Form.Group>
        <Button className="ml-2" variant="primary" onClick={saveInvoiceData} disabled={loading}>
          <div>
          Mentés és tovább a számlázásra
          {loading && loadingTarget===SAVE_INVOICE_FORM && (
                <ButtonLoader/> )}
          </div>
        </Button>
        <Button className="ml-2" variant="primary" onClick={backButtonClick}>
          Vissza
        </Button>
      </Form>
    </>
  );
});
export default WmInvoiceForm;
