import React, {  useState, useEffect } from 'react';
import InstallPWA from '../InstallPwa';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { userStatus} from './WmUtils'

 const WmNavBar=props=>{
    const [propState, setPropState] = useState(props);

    useEffect(() => {
        setPropState(props);
      }, [props]);

    return (
            <Navbar bg="primary" variant="dark" expand="sm" collapseOnSelect >
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src="/static/img/icon.png"
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
      Vízóracsere app
    </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav variant="pills" activeKey={propState.activeSelection} className="mr-auto" onSelect={propState.selectionCallBack}>
  {/*                       <Nav.Item>
                            <Nav.Link eventKey="Start" title="Start">
                                Nyitó oldal
                            </Nav.Link>
                        </Nav.Item> */}
                        {propState.comStat!==userStatus.logged_out?
                        <>
                        <Nav.Item>
                            <Nav.Link eventKey="Worklist" title="Worklist">
                                    Feladatok
                            </Nav.Link>
                        </Nav.Item>
                            <Nav.Item >
                                <Nav.Link eventKey="Logout" title="Logout">
                                    Kijelentkezés
                            </Nav.Link>
                            </Nav.Item>
                            </>
                            :
                            <Nav.Item>
                                <Nav.Link eventKey="Login" title="Login">
                                    Bejelentkezés
                            </Nav.Link>
                            </Nav.Item>
                        }
                    </Nav>
                </Navbar.Collapse>
                <InstallPWA />

            </Navbar>

    );
}
export default  WmNavBar
