
/**********************
 * returns a job object with the following props:
 *  status: if 0 - no change occured
 *          if 1 - the return object contains the new values to be set
 *  errorMessage: if not undefined, to be shown for the customer (not the desired jobtype & quantity selected)
 *  rest of the object is an element of the job array (e. g. "id":1,"version":1607512513922313,"quantity":2,"time_duration":40,"price":"12000.00","jobtype":1)
 *        (the most importants are maybe: job.jobtype, job.price, job.id, job.quantity :) )
 * input:
 *  jobs: array of jobs e.g. [{"id":1,"version":1607512513922313,"quantity":2,"time_duration":40,"price":"12000.00","jobtype":1},{"id":2,...
 *  new_job_quantity: desired quantity
 *  new_jobtype_id: desired jobtype
 *  saved_job_quantity: the last valid quantity for the last valid jobtype selection
 *  forced: if false, a compare of the old states (jobtype_id & quantity) will be executed prior to the searching algorithm.
 *      otherwise the function executes without  comparision of the old & new states of jobtype_id and quantity.
 *      (use for example if the user hits an enter in the text input field - to force to recalculate the price)
 ************************/
/*eslint-disable eqeqeq*/
export const findNewJob = (jobs, new_job_quantity, new_jobtype_id, saved_job_quantity, saved_jobtype_id, forced) => {

    if (!forced && (saved_job_quantity == new_job_quantity && new_jobtype_id== saved_jobtype_id))
        return {
            status: 0
        };

    //search for the desired values
    let job = jobs.find(element => element.jobtype == new_jobtype_id && element.quantity== new_job_quantity);
    let errorMessage =undefined;
    if (!job) {
        //original request not found
        errorMessage="A feladattípus és a megadott mennyiség érvénytelen, ellenőrizze az árat!";
        //try to select the first valid job record with saved quantity
        job = jobs.find(element => element.jobtype == new_jobtype_id && element.quantity == saved_job_quantity);
        if (!job) {
            //try to select the first valid job record without the quantity
            job = jobs.find(element => element.jobtype == new_jobtype_id);
        }
    }

    return {
        ...job,
        status: job ? 1 : 0,
        errorMessage
    }
}

export default findNewJob;
