

import React, { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import findNewJob from '../shared/findJob';
import useTaskDetailsFormControlStore from '../stores/TaskDetailsFormControlStore';
import useJobTypeStore from "../stores/JobTypeStore";
import usePriceListStore from '../stores/PriceListStore';
import useSettingsStore from '../stores/SettingsStore';

/*eslint-disable eqeqeq*/
export const WorkSheetForm = props => {

    const [propState, setPropState] = useState(props);
    const { jobTypes } = useJobTypeStore();
    const { priceList } = usePriceListStore();
    const { settings } = useSettingsStore();

    const { isButtonDisabled } = useTaskDetailsFormControlStore((state) => ({
        isButtonDisabled: state.isButtonDisabled
    }));

    const jobQuantityRef = useRef();
    const jobNameRef = useRef();
    const workPriceRef = useRef();
    const procTypeRef = useRef();
    const workDiscountPercentageRef = useRef();

    const propStateTaskListActTask = useRef(props.taskList.find(x => x.task_id === props.actTask));
    const isMountedComponent = useRef(true);

    useEffect(() => {
        if (isMountedComponent.current) {
            setPropState(props);
            propStateTaskListActTask.current = props.taskList.find(x => x.task_id === props.actTask);
        }
        return () => {
            isMountedComponent.current = false;
        };
    }, [props]);


    const onInputChange = () => {
        const newTaskListState = [...propState.taskList];
        const actTaskListItem = newTaskListState.find(x => x.task_id === propState.actTask);
        const workDiscountPercentageValue = workDiscountPercentageRef?.current?.value ?? 0;

        if (
            actTaskListItem.job_quantity != jobQuantityRef.current.value ||
            actTaskListItem.job_jobtype_fk != jobNameRef.current.value ||
            actTaskListItem.work_discount_percentage != workDiscountPercentageValue
        ) {
            const newJobRecord = findNewJob(
                priceList,
                jobQuantityRef.current.value,
                jobNameRef.current.value,
                actTaskListItem.job_quantity,
                actTaskListItem.job_jobtype_fk,
                true /// Forced
            );
            console.log(newJobRecord);
            if (newJobRecord.errorMessage) {
                toast.warn(newJobRecord.errorMessage);
            }
            if (newJobRecord.status === 1) {
                actTaskListItem.job_quantity = jobQuantityRef.current.value = newJobRecord.quantity;
                actTaskListItem.job_jobtype_fk = jobNameRef.current.value = newJobRecord.jobtype;
                actTaskListItem.work_price = workPriceRef.current.value = Math.floor(newJobRecord.price * (1 - workDiscountPercentageValue / 100));
                actTaskListItem.work_job_fk = newJobRecord.id;
            }
        }
        actTaskListItem.task_process_type = procTypeRef.current.value;
        actTaskListItem.work_price = workPriceRef.current.value;
        actTaskListItem.work_discount_percentage = workDiscountPercentageValue;
        propState.SetTaskList(newTaskListState);
    }

    const doDiscountsExist = () => {
        const options = settings?.features?.discounts;
        return options && options.length > 0;
    }

    return (
        <Form >
            <Form.Group controlId="jobQ">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Mennyiség:</small></Form.Label>
                <Form.Control placeholder="Mennyiség"
                    onBlur={onInputChange}
                    onClick={onInputChange}
                    onKeyDown={e => (e.key === "Enter" ? onInputChange() : null)}
                    defaultValue={propStateTaskListActTask.current.job_quantity} name="jobQ" ref={jobQuantityRef}
                    disabled={isButtonDisabled} />
            </Form.Group>

            <Form.Group controlId="jobName">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Munka típus:</small></Form.Label>
                <Form.Control onChange={onInputChange}
                    as="select"
                    className="my-1 mr-sm-2"
                    custom
                    ref={jobNameRef}
                    disabled={isButtonDisabled}
                    defaultValue={propStateTaskListActTask.current.job_jobtype_fk} >
                    {jobTypes.map((jobItem, idx) => (
                        <option key={idx} value={jobItem.jobtype}>{jobItem.jobtype_name}</option>

                    ))}
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="workPrice">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Ár:</small></Form.Label>
                <Form.Control type="number" placeholder="Ár" onBlur={onInputChange} onDoubleClick={onInputChange} defaultValue={propStateTaskListActTask.current.work_price} name="workPrice" ref={workPriceRef} disabled={isButtonDisabled} />
            </Form.Group>

            {doDiscountsExist() && (
                <Form.Group controlId="workDiscountPercentage">
                    <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Kedvezmény mértéke:</small></Form.Label>
                    <Form.Control onChange={onInputChange}
                        as="select"
                        className="my-1 mr-sm-2"
                        custom
                        ref={workDiscountPercentageRef}
                        disabled={isButtonDisabled}
                        defaultValue={propStateTaskListActTask.current.work_discount_percentage} >
                        {settings?.features?.discounts.map((discount, idx) => (
                            <option key={idx} value={discount}>{discount}%</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            )}

            <Form.Group controlId="processType">
                <Form.Label className="my-1 mr-2" >
                    <small style={{ color: "indigo", textAlign: "left" }}>Munkalap típus:</small>
                </Form.Label>
                <Form.Control onBlur={onInputChange}
                    as="select"
                    className="my-1 mr-sm-2"
                    custom ref={procTypeRef}
                    disabled={isButtonDisabled}
                    defaultValue={propStateTaskListActTask.current.task_process_type || false}>
                    <option value={true}>Papíralapú munkalap</option>
                    <option value={false}>OME munkalap</option>
                </Form.Control>
            </Form.Group>
        </Form>
    );
}
