/**********************
 * returns a formatted text message from the json error message of the backend
 *  every error message is placed in a <p> </p>
 *
 * input:
 *  message: a simple text, this will be prefixed to the returned error message
 *  response: a response object from the axios object.
 *      response.data contains a json object with the error messages.
 *      response.headers must exist and must contain the "content-type" of "application/json"
 *  error: optional closing message
 *
 * usage example:
    getAxiosInstance().delete('api/tasks/' + task_id + '/')
      .then(response => {
        if (response.status === 204) {
          this.deleteWork(work_id, rowIndexToRemove);
        }
        else {
          utils.showBackendError("A törlés nem sikerült", response);
        }
      })
      .catch(error => {
        utils.showBackendError("A törlés nem sikerült", error.response, error);
      });
  }
 ************************/
/*eslint-disable eqeqeq*/
export const backendErrorMessage = (message, response, error) => {
    let msg = message;
    if (response && response.data && response.headers) {
      msg += Object.keys(response.data).map(
        (item) => ((typeof response.data[item]) == "string" || response.data[item] instanceof Array ?
          getFormattedBackendErrorLine(item, response.data[item])
          :
          Object.keys(response.data[item]).map(
            (internalItem) => ((typeof response.data[item][internalItem] == "string") || response.data[item][internalItem] instanceof Array ?
              getFormattedBackendErrorLine(item + "." + internalItem, response.data[item][internalItem])
              :
              Object.keys(response.data[item][internalItem]).map(
                (internalInternalItem) =>
                  getFormattedBackendErrorLine(item + "." + internalItem + "." + internalInternalItem, response.data[item][internalItem][internalInternalItem])
              )
            )
          )
        )
      ).join("");
    }
    if (error) {
      msg += "<p>" + error + "</p>";
    }
    return msg;
  };
  /**********************
   * helper function for backendErrorMessage
   *  every error message is placed in a <p> </p>
   */
  const getFormattedBackendErrorLine = (item, data) =>
    ("<p>" + item + ": " + data + "</p>");

  export default backendErrorMessage;
