
import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import useTaskDetailsFormControlStore, { SAVE_ADDRESS_FORM } from '../stores/TaskDetailsFormControlStore';
import { ButtonLoader } from "./ButtonLoader.jsx"

const WmAddressForm = forwardRef((props, ref) => {
    const [propState, setPropState] = useState(props);
    const selectedTask = useRef(props.taskList.find(x => x.task_id === props.actTask));

    useEffect(() => {
        setPropState(props);
        return(()=>{setPropState(null)});
    }, [props]);

    const nameRef = useRef();
    const coutryRef = useRef();
    const postalCodeRef = useRef();
    const cityRef = useRef();
    const routeRef = useRef();
    const strNoRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const buildingRef = useRef();
    const floorRef = useRef();
    const doorRef = useRef();
    const dbellRef = useRef();
    const noteRef = useRef();

    const { isButtonDisabled, loading, loadingTarget } = useTaskDetailsFormControlStore((state) => ({
        isButtonDisabled: state.isButtonDisabled,
        loading: state.loading,
        loadingTarget: state.loadingTarget,
      }));

    const saveAddress = () => {
        const actAddressData = {
            "contact_name": nameRef.current.value,
            "contact_phone": phoneRef.current.value,
            "address_country": coutryRef.current.value,
            "address_postal_code": postalCodeRef.current.value,
            "address_city": cityRef.current.value,
            "address_route": routeRef.current.value,
            "address_street_number": strNoRef.current.value,
            "address_building": buildingRef.current.value,
            "address_floor": floorRef.current.value,
            "address_door": doorRef.current.value,
            "address_doorbell": dbellRef.current.value,
            "work_note": selectedTask.current.work_note,
            "contact_email": emailRef.current.value
        }
        propState.SetAddressData(actAddressData);
        propState.onSave(SAVE_ADDRESS_FORM);
    }

    const restoreAddressData = () => {
        nameRef.current.value = propState.addressData.contact_name;
        phoneRef.current.value = propState.addressData.contact_phone;
        emailRef.current.value = propState.addressData.contact_email;
        coutryRef.current.value = propState.addressData.address_country;
        postalCodeRef.current.value = propState.addressData.address_postal_code;
        cityRef.current.value = propState.addressData.address_city;
        routeRef.current.value = propState.addressData.address_route;
        strNoRef.current.value = propState.addressData.address_street_number;
        buildingRef.current.value = propState.addressData.address_building;
        floorRef.current.value = propState.addressData.address_floor;
        doorRef.current.value = propState.addressData.address_door;
        dbellRef.current.value = propState.addressData.address_doorbell;
        noteRef.current.value = selectedTask.current.work_note;
        propState.setAddressFormValidated(true);
    }

    const loadAddressData = (address) => {
        nameRef.current.value = address.contact_name;
        postalCodeRef.current.value = address.address_postal_code;
        cityRef.current.value = address.address_city;
        routeRef.current.value = address.address_route;
        strNoRef.current.value = address.address_street_number;
        buildingRef.current.value = address.address_building;
        floorRef.current.value = address.address_floor;
        doorRef.current.value = address.address_door;
        dbellRef.current.value = address.address_doorbell;
        propState.setAddressFormValidated(true);
        propState.onSave();
    }

    useImperativeHandle(ref, () => ({
        loadAddressData
    }));

      const onInputChange= (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          propState.setAddressFormValidated(false);
        } else {
          propState.setAddressFormValidated(true);
        }
      };


    return (
        <Form noValidate id="addressForm" className="was-validated"/* onSubmit={onFormSubmit} */>
            <Form.Group controlId="contactName">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Név (cég v. magánszemély):</small></Form.Label>
                <Form.Control placeholder="Név (cég v. magánszemély)" defaultValue={propState.addressData.contact_name} name="contactName" onChange={onInputChange} ref={nameRef} required disabled={isButtonDisabled}/>
                <Form.Control.Feedback type="invalid">
                    Ez a mező nem lehet üres.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactPhone">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Telefon:</small></Form.Label>
                <Form.Control placeholder="Telefon" defaultValue={propState.addressData.contact_phone} name="contactPhone" ref={phoneRef}  onChange={onInputChange} required disabled={isButtonDisabled}/>
                <Form.Control.Feedback type="invalid">
                    Ez a mező nem lehet üres.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactEmail">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Email:</small></Form.Label>
                <Form.Control placeholder="Email" pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" defaultValue={propState.addressData.contact_email} name="contactEmail" ref={emailRef}  onChange={onInputChange} required disabled={isButtonDisabled}/>
                <Form.Control.Feedback type="invalid">
                    Ez a mező nem lehet üres vagy nem megfelelő az email formátuma.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactCountry">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Ország:</small></Form.Label>
                <Form.Control  placeholder="Ország" defaultValue={propState.addressData.address_country} name="contactCountry" ref={coutryRef} onChange={onInputChange} required disabled={isButtonDisabled}/>
                <Form.Control.Feedback type="invalid">
                    Ez a mező nem lehet üres.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactPostalCode">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Irányítószám:</small></Form.Label>
                <Form.Control  placeholder="Irányítószám" defaultValue={propState.addressData.address_postal_code} name="contactPostalCode" ref={postalCodeRef} onChange={onInputChange} required disabled={isButtonDisabled}/>
                <Form.Control.Feedback type="invalid">
                    Ez a mező nem lehet üres.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactCity">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Város:</small></Form.Label>
                <Form.Control  placeholder="Város" name="invcity" defaultValue={propState.addressData.address_city} ref={cityRef} onChange={onInputChange} required disabled={isButtonDisabled}/>
                <Form.Control.Feedback type="invalid">
                    Ez a mező nem lehet üres.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactRoute">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Közterület:</small></Form.Label>
                <Form.Control placeholder="Közterület" name="invroute" defaultValue={propState.addressData.address_route} ref={routeRef} onChange={onInputChange} required disabled={isButtonDisabled}/>
                <Form.Control.Feedback type="invalid">
                    Ez a mező nem lehet üres.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactStrNo">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Szám:</small></Form.Label>
                <Form.Control  placeholder="Szám" name="invstrno"defaultValue={propState.addressData.address_street_number} onChange={onInputChange} ref={strNoRef} required disabled={isButtonDisabled}/>
                <Form.Control.Feedback type="invalid">
                    Ez a mező nem lehet üres.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="contactBuilding">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Épület:</small></Form.Label>
                <Form.Control  placeholder="Épület" defaultValue={propState.addressData.address_building} name="contactBuilding" ref={buildingRef} disabled={isButtonDisabled}/>
            </Form.Group>
            <Form.Group controlId="contactFloor">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Emelet:</small></Form.Label>
                <Form.Control  placeholder="Emelet" defaultValue={propState.addressData.address_floor} name="contactFloor" ref={floorRef} disabled={isButtonDisabled}/>
            </Form.Group>
            <Form.Group controlId="contactDoor">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Ajtó:</small></Form.Label>
                <Form.Control  placeholder="Ajtó" defaultValue={propState.addressData.address_door} name="contactDoor" ref={doorRef} disabled={isButtonDisabled}/>
            </Form.Group>
            <Form.Group controlId="contactDBell">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Kapucsengő:</small></Form.Label>
                <Form.Control  placeholder="Kapucsengő" defaultValue={propState.addressData.address_doorbell} name="contactDBell" ref={dbellRef} disabled={isButtonDisabled}/>
            </Form.Group>
            <Form.Group>
                <div style={{marginBottom:'20px'}}>
                    <span>
                        <small style={{ color: "indigo", textAlign: "left", marginRight:'10px', display:'block' }} ref={noteRef}>Diszpécser megjegyzés a Szerelő felé:</small>
                        <small disabled={isButtonDisabled}>{selectedTask.current.work_note}</small>
                    </span>
                </div>
            </Form.Group>
            <Button className="ml-2" onClick={saveAddress} disabled={isButtonDisabled || loading}>
            <div>
            Cím adatok mentése
            {loading && loadingTarget===SAVE_ADDRESS_FORM && (
                <ButtonLoader/> )}
            </div>
            </Button>
            <Button className="ml-2" variant="primary" onClick={restoreAddressData} disabled={isButtonDisabled}>Mégse</Button>
        </Form>
    );
});
export default WmAddressForm;
