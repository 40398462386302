import React, { useState, useEffect, useRef } from "react";
import Toast from "react-bootstrap/Toast";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import DetailedTask from "./TaskDetails";
import { FaRegClock, FaTasks } from "react-icons/fa";
import backendErrorMessage from "../shared/backendError";
import { showBackendError } from "./ShowError";
import { userStatus, apiURL, GetAnyList, openPdf } from "./WmUtils";
import { toast } from "react-toastify";
import { fetchInitialItems } from "../worksheet/fetchFromDB";
import useVersionStore from "../stores/versionStore";
import useJobTypeStore from "../stores/JobTypeStore";
import usePriceListStore from "../stores/PriceListStore";
import useWaterMeterStore from "../stores/WaterMeterStore";
import useSealStore from "../stores/SealStore";
import useSettingsStore from "../stores/SettingsStore";

const workitemsURL = apiURL + "/workitems/?task_status=SCHEDULED,STARTED,FAILED,FINISHED";
const jobsURL = apiURL + "/jobs/";
const jobTypesURL = apiURL + "/jobtypes/";
const serverVersionURL = apiURL + "/server-version/";
const sealsURL = apiURL + "/seals/";
const wmURL = apiURL + "/watermeters/";
const settingsURL = apiURL + "/settings/";

const taskStateList = Object(require("../task_state_list"));

const dateSort = (a, b) => {
  if (a.task_planned_time < b.task_planned_time) {
    return -1;
  }
  if (a.task_planned_time > b.task_planned_time) {
    return 1;
  }
  return 0;
};

const WmFuncBrief = (props) => {
  const { mobileVersion, serverVersion, setServerVersion } = useVersionStore();
  const { setJobTypes } = useJobTypeStore();
  const { setPriceList } = usePriceListStore();
  const { waterMeters, setWaterMeters } = useWaterMeterStore();
  const { seals, setSeals } = useSealStore();
  const { setSettings } = useSettingsStore();

  const [taskList, SetTaskList] = useState(null);
  const [isDailyTasks, SetIsDailyTasks] = useState(true); //true: daily, false: all
  const [actTask, SetActTask] = useState(null);
  const [actTaskState, SetActTaskState] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [addressData, SetAddressData] = useState(null);
  const [wmWorkList, SetWmWorkList] = useState([]);
  const [otherWorkList, SetOtherWorkList] = useState([]);
  const [propState, setPropState] = useState(props);
  const [taskEnabled, setTaskEnabled] = useState({});

  const wmListRef = useRef();
  const sealListRef = useRef();

  useEffect(() => {
    setPropState(props);
  }, [props]);

  useEffect(() => {
    GetAnyList(serverVersionURL)
      .then((serverVersion) => {
        setServerVersion(serverVersion?.data.version);
      })
      .catch((error) => {
        console.error("Hiba a verzió lekérdezésnél: ", error);
      });
  }, []);

  useEffect(() => {
    // we should update the task list when we "navigate" back from the detailed task, to make wmWorklist up to date
    // if we don't do that, a bug occurs when we read watermeter data from json and don't save them
    // in this case if we navigate to an other task with saved watermeter data, we can load wm data from json which shouldn't be allowed
    if (!actTask) onAskForTaskList(null);
  }, [actTask]);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };


  const askForDailyTaskList = async (e) => {
    SetIsDailyTasks(true);
    onAskForTaskList(e, true);
  }

  const askForAllTaskList = async (e) => {
    SetIsDailyTasks(false);
    onAskForTaskList(e, false);
  }

  const onAskForTaskList = async (event, daily = isDailyTasks) => {
    event?.preventDefault();
    if (propState.comStat === userStatus.offline) {
      toast.warning(
        <div>
          Az alkalmazás offline állapontban van,
          <br /> nem lehetséges a lekérdezés
        </div>
      );
      return;
    }

    const url = daily ? `${workitemsURL}&end_date=${formatDate(new Date())}` : workitemsURL;


    const retTaskList = await GetAnyList(url).then((data) => {
      if (data?.status === 200) {
        const retList = data.data;
        retList.sort(dateSort);
        return retList;
      } else {
        if (data?.status === 403) {
          propState.SetComStat(userStatus.unautorized);
        } else {
          propState.SetComStat(userStatus.offline);
        }
        showBackendError(backendErrorMessage("Hiba a feladatok letöltésekor. ", data?.status));
        return null;
      }
    });
    if (!retTaskList) return;
    const tempTaskList = retTaskList.filter((taskListItem) => taskStateList[taskListItem.task_status]);

    let tempTaskEnabled = {};
    await Promise.all(
      tempTaskList.map(async (item) => {
        tempTaskEnabled["task" + item.task_id] = false;

        const relatedTaskList = await GetAnyList(apiURL + "/tasks/" + item.task_id + "/related/");
        item.relatedTaskList = relatedTaskList?.data;
      })
    );
    setTaskEnabled(tempTaskEnabled);
    SetTaskList(tempTaskList);

    const retJobTypeList = await GetAnyList(jobTypesURL);

    if (!retJobTypeList || !retJobTypeList.status || retJobTypeList.status !== 200) {
      if (retJobTypeList?.status === 403) {
        propState.SetComStat(userStatus.unautorized);
      } else {
        propState.SetComStat(userStatus.offline);
      }
      showBackendError(backendErrorMessage("Hiba a feladat nevek letöltésekor. ", retJobTypeList?.status));
      return;
    }

    setJobTypes(retJobTypeList.data.map((jobNameItem) => ({ jobtype: jobNameItem.id, jobtype_name: jobNameItem.name })));

    const retJobList = await GetAnyList(jobsURL);
    if (!retJobList || !retJobList.status || retJobList.status !== 200) {
      if (retJobList?.status === 403) {
        propState.SetComStat(userStatus.unautorized);
      } else {
        propState.SetComStat(userStatus.offline);
      }
      showBackendError(backendErrorMessage("Hiba az árlista letöltésekor. ", retJobList?.status));
      return;
    }

    setPriceList(retJobList.data.map((jobItem) => ({
      id: jobItem.id,
      quantity: jobItem.quantity,
      time_duration: jobItem.time_duration,
      price: jobItem.price,
      jobtype_name: retJobTypeList.data.find((jtItem) => jtItem.id === jobItem.jobtype).name,
      jobtype: jobItem.jobtype,
    })));

    const retSettings = await GetAnyList(settingsURL);
    if (!retSettings || !retSettings.status || retSettings.status !== 200 || !retSettings.data) {
      if (retSettings?.status === 403) {
        propState.SetComStat(userStatus.unautorized);
      } else {
        propState.SetComStat(userStatus.offline);
      }
      showBackendError(backendErrorMessage("Hiba a beállítások letöltésekor. ", retSettings?.status));
      return;
    }
    setSettings(retSettings.data[0]);

    await downloadWaterMetersandSeals();
  };

  const downloadWaterMetersandSeals = async () => {
    const retWmList = await GetAnyList(wmURL);
    if (!retWmList || !retWmList.status || retWmList.status !== 200) {
      if (retWmList?.status === 403) {
        propState.SetComStat(userStatus.unautorized);
      } else {
        propState.SetComStat(userStatus.offline);
      }
      showBackendError(backendErrorMessage("Hiba a vízóra azonosító adatok letöltésekor. ", retWmList?.status));
      return;
    }
    setWaterMeters(retWmList.data);

    const retSealList = await GetAnyList(sealsURL);
    if (!retSealList || !retSealList.status || retSealList.status !== 200) {
      if (retSealList?.status === 403) {
        propState.SetComStat(userStatus.unautorized);
      } else {
        propState.SetComStat(userStatus.offline);
      }
      showBackendError(backendErrorMessage("Hiba a plomba azonosító adatok letöltésekor. ", retSealList?.status));
      return;
    }
    setSeals(retSealList.data);

  }

  // const getByKey = (arr, key) => (arr.find(x => Object.keys(x)[0] === key) || {})[key]
  //END OF ONASKFORTASKLIST
  const invoiceSetter = () => {
    if (actTask !== null) {
      const actTaskListItem = taskList.find((x) => x.task_id === actTask);
      const invoicePreData = taskList.find((x) => x.task_id === actTask).invoice_company_name
        ? {
            contact_name: actTaskListItem.invoice_company_name,
            address_country: actTaskListItem.invoice_address_country,
            tax_number: actTaskListItem.invoice_tax_number,
            address_postal_code: actTaskListItem.invoice_address_postal_code,
            address_city: actTaskListItem.invoice_address_city,
            address_route: actTaskListItem.invoice_address_route,
            address_street_number: actTaskListItem.invoice_address_street_number,
            // New records according to VZ-239
            address_building: actTaskListItem.invoice_address_building,
            address_floor: actTaskListItem.invoice_address_floor,
            address_door: actTaskListItem.invoice_address_door,
            address_doorbell: actTaskListItem.invoice_address_doorbell,
            // EO New records according to VZ-239
          }
        : {
            contact_name: actTaskListItem.work_contact_name,
            address_country: actTaskListItem.work_address_country,
            tax_number: actTaskListItem.invoice_tax_number,
            address_postal_code: actTaskListItem.work_address_postal_code,
            address_city: actTaskListItem.work_address_city,
            address_route: actTaskListItem.work_address_route,
            address_street_number: actTaskListItem.work_address_street_number,
            // New records according to VZ-239
            address_building: actTaskListItem.work_address_building,
            address_floor: actTaskListItem.work_address_floor,
            address_door: actTaskListItem.work_address_door,
            address_doorbell: actTaskListItem.work_address_doorbell,
            // EO New records according to VZ-239
          };
      setInvoiceData(invoicePreData);
    }
  };

  const setTaskEnabledByTaskId = (task_id, value) => {
    taskEnabled["task" + task_id] = value;
    setTaskEnabled({ ...taskEnabled });
  };

  const getTaskEnabledByTaskId = (task_id) => {
    return taskEnabled["task" + task_id];
  };

  useEffect(invoiceSetter, [actTask, taskList]);

  const taskItemSetter = (iterator) => {
    const displayDetailedTask = () => {
      SetActTask(taskList[iterator].task_id);

      if (iterator !== null) {
        const actTaskListItem = taskList[iterator];
        const statusStrFromData = actTaskListItem.task_status;
        const statusFromData = taskStateList[statusStrFromData];
        SetActTaskState(statusFromData);

        const addressPreData = {
          contact_name: actTaskListItem.work_contact_name,
          contact_phone: actTaskListItem.work_contact_phone,

          address_country: actTaskListItem.work_address_country,
          address_postal_code: actTaskListItem.work_address_postal_code,
          address_city: actTaskListItem.work_address_city,
          address_route: actTaskListItem.work_address_route,
          address_street_number: actTaskListItem.work_address_street_number,
          address_building: actTaskListItem.work_address_building,
          address_floor: actTaskListItem.work_address_floor,
          address_door: actTaskListItem.work_address_door,
          address_doorbell: actTaskListItem.work_address_doorbell,
          work_note: actTaskListItem.work_note,
          work_address_note: actTaskListItem.work_address_note,
          contact_email: actTaskListItem.work_contact_email,
        };
        SetAddressData(addressPreData);
      } else {
        SetActTaskState(null);
      }
    };

    if (propState.comStat === userStatus.offline) {
      toast.warning(
        <div>
          Az alkalmazás offline állapontban van,
          <br /> a készüléken tárolt adatok fognak betöltődni
        </div>
      );
    }

    if (!getTaskEnabledByTaskId(taskList[iterator].task_id)) {
      let propsForTaskItemGetter = {
        actTask: taskList[iterator].task_id,
        wmWorkList: wmWorkList,
        SetWmWorkList: SetWmWorkList,
        otherWorkList: otherWorkList,
        SetOtherWorkList: SetOtherWorkList,
        wmList: wmListRef,
        sealList: sealListRef,
      };
      fetchInitialItems(propsForTaskItemGetter, waterMeters, seals)
        .then(() => {
          setTaskEnabledByTaskId(taskList[iterator].task_id, true);
          displayDetailedTask();
        })
        .catch(() => {
          toast.error("Hiba a feladat betöltésekor!");
        });
    } else {
      displayDetailedTask();
    }
  };

  const SetActTaskStateCbk = (actState) => {
    SetActTaskState(actState);
    taskList.find((x) => x.task_id === actTask).task_status = actState.key;
  };

  const SetInvoiceDataCbk = (actInvoiceData) => {
    const newState = [...taskList];
    const actTaskListItem = newState.find((x) => x.task_id === actTask);
    actTaskListItem.invoice_company_name = actInvoiceData.contact_name;
    actTaskListItem.invoice_address_country = actInvoiceData.address_country;
    actTaskListItem.invoice_tax_number = actInvoiceData.tax_number;
    actTaskListItem.invoice_address_postal_code = actInvoiceData.address_postal_code;
    actTaskListItem.invoice_address_city = actInvoiceData.address_city;
    actTaskListItem.invoice_address_route = actInvoiceData.address_route;
    actTaskListItem.invoice_address_street_number = actInvoiceData.address_street_number;

    actTaskListItem.invoice_address_building = actInvoiceData.address_building;
    actTaskListItem.invoice_address_floor = actInvoiceData.address_floor;
    actTaskListItem.invoice_address_door = actInvoiceData.address_door;
    actTaskListItem.invoice_address_doorbell = actInvoiceData.address_doorbell;
    SetTaskList(newState);
  };
  /*
            "address_building": buildingNoRef.current.value,
            "address_floor": floorRef.current.value,
            "address_door": doorRef.current.value,
            "address_doorbell": doorBellNoRef.current.value,
             */
  const SetAddressDataCbk = (actAddressData) => {
    SetAddressData(actAddressData);
    const newState = [...taskList];
    const actTaskListItem = newState.find((x) => x.task_id === actTask);
    actTaskListItem.work_contact_name = actAddressData.contact_name;
    actTaskListItem.work_address_country = actAddressData.address_country;
    actTaskListItem.work_address_postal_code = actAddressData.address_postal_code;
    actTaskListItem.work_address_city = actAddressData.address_city;
    actTaskListItem.work_address_route = actAddressData.address_route;
    actTaskListItem.work_address_street_number = actAddressData.address_street_number;
    actTaskListItem.work_contact_phone = actAddressData.contact_phone;
    actTaskListItem.work_address_building = actAddressData.address_building;
    actTaskListItem.work_address_floor = actAddressData.address_floor;
    actTaskListItem.work_address_door = actAddressData.address_door;
    actTaskListItem.work_address_doorbell = actAddressData.address_doorbell;
    actTaskListItem.work_note = actAddressData.work_note;
    actTaskListItem.work_address_note = actAddressData.work_address_note;
    actTaskListItem.work_contact_email = actAddressData.contact_email;
    SetTaskList(newState);
    invoiceSetter();
  };

  const getTimeBadgeStrFromZuluTime = (dateStr, defaultValue) => {
    if (dateStr) {
      try {
        let d = new Date(dateStr);
        return dateStr.substr(5, 5).replace("-", "/") + "-" + d.toTimeString().substr(0, 5);
      } catch (err) {
        //format error
        console.log("[getTimeStrFromZuluTime]: date conversion failed (" + err + ")");
        return defaultValue;
      }
    } else return defaultValue;
  };

  const ToastList = () => {
    if (!!taskList?.length) {
      return taskList.map((item, iii) => {
        return (
          <Toast className="mx-0 px-0" key={iii}>
            <Toast.Header closeButton={false}>
              <h6 className="mr-auto">
                <Badge pill variant="info">
                  {item.work_time_window_start && item.work_time_window_start.slice(0, -3)} - {item.work_time_window_end && item.work_time_window_end.slice(0, -3)}
                </Badge>
              </h6>
              <Badge variant="outline-info" size="sm">
                <table>
                  <tbody>
                    <tr>
                      <td>{item.task_no}</td>
                    </tr>
                    <tr>
                      <td>{item.work_no}</td>
                    </tr>
                  </tbody>
                </table>
              </Badge>
              {item.relatedTaskList && (
                <DropdownButton
                  id="dropdown-basic-button"
                  variant="info"
                  className="px-2"
                  title={<FaRegClock />}
                  disabled={item.relatedTaskList.length === 0}
                  onSelect={async (eventKey) => await openPdf(apiURL + "/tasks/" + eventKey + "/infosheet/")}
                >
                  {item.relatedTaskList.map((task) => {
                    const statusKey = task.fields.task_status;
                    const statusEntry = Object.entries(taskStateList).find(([key, value]) => key === statusKey);
                    const statusName = statusEntry ? statusEntry[1].name : "Ismeretlen státusz";
                    return (
                      <Dropdown.Item key={task.task_id} eventKey={task.fields.task_id}>
                        {task.fields.task_date} - {statusName}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              )}
              <Button onClick={() => taskItemSetter(iii)} variant="outline-dark" id="color" className={`${getTaskEnabledByTaskId(item.task_id) ? "colorGreen" : ""} px-2 pb-0`}>
                <h5>
                  <FaTasks />
                </h5>
              </Button>
            </Toast.Header>
            <Toast.Body className="py-0">
              <Table className="py-0 my-0" size="sm" responsive width="100%">
                <tbody>
                  <tr>
                    {/* "task_planned_time": "2021-01-18T13:00:00Z", */}
                    <td>
                      <Badge pill variant="secondary">
                        {getTimeBadgeStrFromZuluTime(item.task_planned_time)}
                      </Badge>
                    </td>

                    <td style={{ textAlign: "right" }}>
                      {" "}
                      <h6 className="pb-0">
                        {" "}
                        <Badge pill variant={taskStateList[item.task_status].variant}>
                          {taskStateList[item.task_status].name}
                        </Badge>
                      </h6>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <Table striped hover={true} className="pt-0 mt-0">
                <tbody>
                  <tr className="py-0 my-0">
                    <td>
                      <strong className="pb-0"> {item.work_contact_name}</strong>&nbsp;{item.work_contact_phone}
                    </td>
                  </tr>
                  {item.work_note && item.work_note.trim().length > 0 && (
                    <tr>
                      <td>{item.work_note}</td>
                    </tr>
                  )}
                  <tr className="py-0 my-0">
                    <td>
                      <a target="_blank" href={`https://www.google.com/maps?q=${item.work_formatted_address}`}>
                        {item.work_formatted_address}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {item.work_address_building && <span>Épület: {item.work_address_building} </span>}
                      {item.work_address_floor && <span>Emelet: {item.work_address_floor} </span>}
                      {item.work_address_door && <span>Ajtó: {item.work_address_door} </span>}
                      {item.work_address_doorbell && <span>Csengő: {item.work_address_doorbell} </span>}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Toast.Body>
          </Toast>
        );
      });
    } else {
      return <div>Nincs adat!</div>;
    }
  };
  //  if (actTask !== null ) {
  if (actTask !== null && invoiceData !== null) {
    return (
      <div>
        <DetailedTask
          taskList={taskList}
          SetTaskList={SetTaskList}
          taskStateList={taskStateList}
          actTaskState={actTaskState}
          SetActTaskState={SetActTaskStateCbk}
          actTask={actTask}
          SetActTask={SetActTask}
          invoiceData={invoiceData}
          setInvoiceData={SetInvoiceDataCbk}
          addressData={addressData}
          SetAddressData={SetAddressDataCbk}
          wmWorkList={wmWorkList}
          SetWmWorkList={SetWmWorkList}
          otherWorkList={otherWorkList}
          SetOtherWorkList={SetOtherWorkList}
          comStat={propState.comStat}
          SetComStat={propState.SetComStat}
          wmList={wmListRef}
          sealList={sealListRef}
        />
      </div>
    );
  } else {
    return (
      <div>
        <Button style={{ margin: "0 1rem 0 0" }} variant="info" onClick={(e) => askForDailyTaskList(e)}>
          Mai feladatok
        </Button>
        <Button style={{ margin: "0 0 0 1rem" }} variant="info" onClick={(e) => askForAllTaskList(e)}>
          Összes feladat
        </Button>
        <div>Mobil verzió: {mobileVersion}</div>
        <div>Szerver verzió: {serverVersion}</div>
        <Version show={serverVersion != mobileVersion}></Version>
        <ToastList />
      </div>
    );
  }
};

const Version = ({ show }) => {
  if (show) {
    return <div style={{ color: "red" }}>{"Új frissítés áll rendelkezésre, kérem telepítse!"}</div>;
  } else return null;
};

const TaskList = (props) => {
  const [propState, setPropState] = useState(props);

  useEffect(() => {
    setPropState(props);
  }, [props]);

  return (
    <Container className="p-2">
      <Jumbotron>
        <h1 className="header">Feladatok</h1>
        <WmFuncBrief comStat={propState.comStat} SetComStat={propState.SetComStat} />
        <hr />
      </Jumbotron>
    </Container>
  );
};
export default TaskList;
