import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import './pwa_install.css';


const InstallPWA = () => {
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    useEffect(() => {
        const handler = e => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const onClick = evt => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    };
    if (!supportsPWA) {
        return null;
    }
    return (
        <Button
            variant='info'
            onClick={onClick}>
            <div class='AnimInstallText' >Telepítés</div>
        </Button>
    );
};

export default InstallPWA;
