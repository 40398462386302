import { useEffect, useState, useCallback, useRef } from "react";
import { WmPhotoBrief } from "../app/TakePhoto";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import OtherItemForm from "./InvitemForm";
import ImageViewer from "react-simple-image-viewer";
import * as types from "../shared/types";
import useTaskDetailsFormControlStore from "../stores/TaskDetailsFormControlStore";
import useDialogStore from "../stores/useDialogStore";

const OtherWorkListAcrdn = (props) => {
  const [propState, setPropState] = useState(props);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const propStateListActTask = useRef(props.otherWorkList[`${props.actTask}`]);

  const { isButtonDisabled } = useTaskDetailsFormControlStore((state) => ({
    isButtonDisabled: state.isButtonDisabled,
  }));

  const { openModal, closeModal } = useDialogStore();

  useEffect(() => {
    setPropState(props);
    propStateListActTask.current = props.otherWorkList[`${props.actTask}`];
  }, [props]);

  const handleWorkDelete = (index) => {
    openModal({
      title: "Munka törlése",
      confirmation: "Biztosan törölni szeretné ezt a munkát?",
      onConfirm: () => {
        onDeleteButtonClick(index);
        closeModal();
      },
      onCancel: closeModal,
    });
  };

  const handleImageDelete = (workitemIdx, imageIdx) => {
    openModal({
      title: "Kép törlése",
      confirmation: "Biztosan törölni szeretné ezt a képet?",
      onConfirm: () => {
        onOtherThDelClick(workitemIdx, imageIdx);
        closeModal();
      },
      onCancel: closeModal,
    });
  };

  const onDeleteButtonClick = (index) => {
    propState.setActiveKey(null);
    propStateListActTask.current.wmlist.splice(index, 1);
  };
  const onThumbnailClick = (index) => {
    openImageViewer(index);
  };

  const onOtherThDelClick = (workitemIdx, imageIdx) => {
    const tempState = { ...propState.otherWorkList };
    tempState[`${propState.actTask}`].wmlist[workitemIdx].images.splice(imageIdx, 1);
    propState.SetOtherWorkList(tempState);
  };
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  /*eslint-disable eqeqeq*/
  if (propStateListActTask.current && propStateListActTask.current.wmlist) {
    return (
      <>
        {propStateListActTask.current.wmlist.map((fii, iii) => {
          return (
            <div key={(iii + 1000).toString() + "wrap"}>
              {fii.price != types.INVOICE_ITEM_EXTREM_PRICE && (
                <Card key={(iii + 1000).toString() + "invicrd"}>
                  <Card.Header style={{ textAlign: "center" }}>
                    <Accordion.Toggle as={Button} variant="link" eventKey={(iii + 1000).toString()}>
                      <h4 variant="light">Egyéb munka</h4>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={(iii + 1000).toString()}>
                    <Card.Body>
                      {propState.activeKey === (iii + 1000).toString() && (
                        <>
                          <OtherItemForm
                            otherWorkList={propState.otherWorkList}
                            SetOtherWorkList={propState.SetOtherWorkList}
                            actTask={propState.actTask}
                            item={iii}
                            key={(iii + 1000).toString() + "invitfrmwrp"}
                            keyProp={(iii + 1000).toString()}
                            focusRefList={propState.focusRefList}
                            propStateListWmItem={propState.propStateListWmItem}
                            wmWorkList={propState.wmWorkList}
                            SetWmWorkList={propState.SetWmWorkList}
                          />

                          <div>
                            <Button
                              variant="danger"
                              onClick={() => handleWorkDelete(iii)}
                              className="mr-4 mb-2"
                              disabled={!!propStateListActTask.current.wmlist[iii].id || isButtonDisabled}
                            >
                              Munka törlése
                            </Button>
                            <WmPhotoBrief
                              taskList={propState.taskList}
                              anyWorkList={propState.otherWorkList}
                              SetAnyWorkList={propState.SetOtherWorkList}
                              actTask={propState.actTask}
                              item={iii}
                              key={(iii + 1000).toString() + "invitphoto"}
                              file_name_ext="i"
                            />
                            <>
                              <div style={{ columns: "2 auto" }}>
                                {propStateListActTask.current.wmlist[iii].images.map((imgItem, idx) => (
                                  <div key={idx}>
                                    <img src={imgItem.image} alt="Wm-app kép" width="100%" onClick={() => onThumbnailClick(idx)} />
                                    <br />
                                    <Button
                                      key="otherWlImgDel"
                                      variant="danger"
                                      onClick={() => handleImageDelete(iii, idx)}
                                      className="py-0 mt-1 mb-2"
                                      disabled={!!propStateListActTask.current.wmlist[iii].id || isButtonDisabled}
                                    >
                                      Kép törlése
                                    </Button>
                                  </div>
                                ))}{" "}
                              </div>
                              {isViewerOpen && (
                                <ImageViewer
                                  src={propStateListActTask.current.wmlist[iii].images.map((imgRec) => imgRec.image)}
                                  currentIndex={currentImage}
                                  onClose={closeImageViewer}
                                />
                              )}
                            </>
                          </div>
                        </>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
            </div>
          );
        })}
      </>
    );
  }
  return null;
};
export default OtherWorkListAcrdn;
