import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ConfirmationModal = ({ show, title, confirmation, okLabel, cancelLabel, onConfirm, onCancel, enableEscape }) => {
  return (
    <Modal animation={false} show={show} onHide={onCancel} backdrop={enableEscape ? true : "static"} keyboard={enableEscape}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{confirmation}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onCancel}>{cancelLabel}</Button>
        <Button className="button-l" bsStyle="primary" onClick={onConfirm}>
          {okLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  confirmation: PropTypes.string,
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  enableEscape: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  title: "Confirmation",
  okLabel: "OK",
  cancelLabel: "Cancel",
  enableEscape: true,
};

export default ConfirmationModal;
