import { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import WmInvoiceForm from "./InvoiceForm";
import WmInvoiceSummary from "./InvoiceSummary";
import WmAddressForm from "./AddressForm";
import WmWorkListAcrdn from "../worksheet/WmeterList";
import OtherWorkListAcrdn from "../worksheet/InvitemList";
import WorkSheetPhoto from "../worksheet/WorkSheetPhoto";
import { showBackendError } from "./ShowError";
import ReactModal from "react-modal";
import { backendErrorMessage } from "../shared/backendError";
import { ControlButtons } from "./ControlButtons";
import { toast } from "react-toastify";
import { SignatureBrief } from "./TakeSignature";
import Form from "react-bootstrap/Form";
import { userStatus, apiURL, showConfirmationDialog, GetAnyList } from "./WmUtils";
import { upLoadWorkSheet } from "../worksheet/upLoadWorksheet";
import { WorkSheetForm } from "../worksheet/WorksheetForm";
import useTaskDetailsFormControlStore, { SAVE_WORKSHEET, SAVE_SIGNED_WORKSHEET, SAVE_AND_SEND_WORKSHEET, SAVE_AND_NEXT_TO_INVOICING } from "../stores/TaskDetailsFormControlStore";
import useOMEContentStore from "../stores/useOMEContentStore.jsx";
import useInvoiceStatusStore from "../stores/useInvoiceStatusStore.jsx";
import { formatSealWatermeterId } from './WmUtils';
import { ButtonLoader } from "./ButtonLoader.jsx"
import useWaterMeterStore from "../stores/WaterMeterStore";
import useSealStore from "../stores/SealStore";

import * as types from "../shared/types";
import useSettingsStore from "../stores/SettingsStore.jsx";

const taskURL = apiURL + "/tasks";

/*eslint-disable eqeqeq*/
const DetailedTask = (props) => {
  const childRef = useRef();
  const [propState, setPropState] = useState(props);
  const [activeKey, setActiveKey] = useState(null);
  const [activeWmTabKey, setActiveWmTabKey] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true);
  const [overAllAcrdActiveKey, setOverAllAcrdActiveKey] = useState("1");
  const [workSheetPhotoItemSerial, setWorkSheetPhotoItemSerial] = useState(null);
  const [focusRefKey, setFocusRefKey] = useState(null);
  const [addressFormValidated, setAddressFormValidated] = useState(true);
  const [invoiceFormValidated, setInvoiceFormValidated] = useState(true);
  const { content, setContent } = useOMEContentStore((state) => ({ content: state.content, setContent: state.setContent }));
  const { setInvoiceStatus } = useInvoiceStatusStore((state) => ({ invoiceStatus: state.invoiceStatus, setInvoiceStatus: state.setInvoiceStatus }));
  const [isAnySavedWaterMeterExists, setIsAnySavedWaterMeterExists] = useState(false);

  const { isButtonDisabled, setIsButtonDisabled, loading, setLoading, loadingTarget, setLoadingTarget } = useTaskDetailsFormControlStore((state) => ({
    isButtonDisabled: state.isButtonDisabled,
    setIsButtonDisabled: state.setIsButtonDisabled,
    loading: state.loading,
    setLoading: state.setLoading,
    loadingTarget: state.loadingTarget,
    setLoadingTarget: state.setLoadingTarget,
  }));
  const { waterMeters } = useWaterMeterStore();
  const { seals } = useSealStore();
  const { settings } = useSettingsStore();
  const selectedTask = useRef(props.taskList.find((x) => x.task_id === props.actTask));

  /// To elmininate 'Warning: Can't perform a React state update on an unmounted component...'
  const isMountedComponent = useRef(true);
  const focusRefList = useRef({});
  const emailRef = useRef();
  const mechanicNoteRef = useRef();

  useEffect(() => {
    selectedTask.current = props.taskList.find((x) => x.task_id === props.actTask);
  }, [props.actTask]);

  useEffect(() => {
    selectedTask.current = props.taskList.find((x) => x.task_id === props.actTask);
    setIsButtonDisabled(props.actTaskState?.key !== "STARTED");
  }, [props.actTask, props.actTaskState]);

  //this useEffect is needed, because in this form there are several propState.SetXXX calls.
  //   These calls are setting the new state in the caller Form's state. Not in this form, because the useState is defined there, and not in this form.
  //   After changing the caller Form's state this [props] event fires and refreshes all the states also in this form (therefore the rendering will also run).
  useEffect(() => {
    setPropState(props);
  }, [props]);

  useEffect(() => {
    if (!!focusRefKey && !!focusRefList) {
      (async function () {
        if (focusRefKey !== "nullfocus") {
          focusRefList.current[focusRefKey].scrollIntoView({ behavior: "smooth" });
        }
      })();
    }
  }, [focusRefKey]);

  useEffect(async () => {
    if (isMountedComponent.current) {
      if (propState.comStat === userStatus.ok) {
        setInvoiceStatus(await getInvoiceStatus());
        setInitialLoading(false);
        const isAnySaved = checkIfWmWorkListSaved();
        setIsAnySavedWaterMeterExists(isAnySaved);
      } else {
        setInitialLoading(false);
      }
    }
    return () => {
      isMountedComponent.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInvoiceStatus = async () => {
    const invoiceStatusRetVal = await GetAnyList(`${apiURL}/tasks/${propState.actTask}/`);
    if (invoiceStatusRetVal?.status === 200) {
      return invoiceStatusRetVal.data.invoice_status;
    }
  };

  useEffect(() => {
    let photoItemIndex = undefined;
    if (overAllAcrdActiveKey === "2" && activeKey === null) {
      propState.otherWorkList[`${propState.actTask}`]?.wmlist?.some((fii, iii) => {
        if (fii.price == types.INVOICE_ITEM_EXTREM_PRICE) {
          photoItemIndex = iii;
          return true;
        }
        return false;
      });
      if (photoItemIndex !== undefined) {
        if (workSheetPhotoItemSerial != photoItemIndex) setWorkSheetPhotoItemSerial(photoItemIndex);
      } else {
        insertNewOther(true /** isPhoto */);
      }
    }
    if (overAllAcrdActiveKey !== "2") {
      if (workSheetPhotoItemSerial !== null) setWorkSheetPhotoItemSerial(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overAllAcrdActiveKey, activeKey, propState.otherWorkList]);

  useEffect(() => {
    if (content && content.task_id === propState.actTask) {
      const actAddressData = {
        contact_name: findSingleTextValueByViewID(content.descriptors, "identification_nameEt"),
        contact_phone: propState.addressData.contact_phone,
        contact_email: propState.addressData.contact_email,
        address_country: propState.addressData.address_country,
        address_postal_code: findSingleTextValueByViewID(content.descriptors, "identification_zipEt"),
        address_city: findSingleTextValueByViewID(content.descriptors, "identification_cityEt").split(" ")[0],
        address_route: findSingleTextValueByViewID(content.descriptors, "identification_streetEt"),
        address_street_number: findSingleTextValueByViewID(content.descriptors, "identification_house_numberEt"),
        address_building: findSingleTextValueByViewID(content.descriptors, "identification_buildingEt"),
        address_floor: findSingleTextValueByViewID(content.descriptors, "identification_stairwayEt"),
        address_door: findSingleTextValueByViewID(content.descriptors, "identification_floorEt"),
        address_doorbell: findSingleTextValueByViewID(content.descriptors, "identification_doorEt"),
      };
      propState.SetAddressData(actAddressData);
      if (overAllAcrdActiveKey === "0") {
        childRef.current.loadAddressData(actAddressData);
      }
    }
  }, [content]);

  useEffect(() => {
    let numOfFelszereles = 0;
    if (content && content.workItems) {
      let newWmWorkList = [];
      content?.workItems.forEach((workItem, index) => {
        const hasEquipment = workItem.some((descriptor) => descriptor.viewID === "caption" && descriptor.textValue === "Felszerelés");
        if (hasEquipment && content && content.task_id === propState.actTask) {
          numOfFelszereles++;
          newWmWorkList = setOMEWorkItems(workItem, newWmWorkList, numOfFelszereles);
        }
      });
      propState.SetWmWorkList(newWmWorkList);
    }
  }, [content]);

  const checkIfWmWorkListSaved = () => {
    const wmWorkList = props.wmWorkList[props.actTask];
    return wmWorkList?.wmlist?.some((item) => item.id !== null); // watermeters that are not saved in db doesn't have id
  };

  useEffect(() => {
    const isAnySaved = checkIfWmWorkListSaved();
    setIsAnySavedWaterMeterExists(isAnySaved);
  }, [props.wmWorkList, props.actTask]);

  const setOMEWorkItems = (workItem, wmWorkList, numOfFelszereles) => {
    let newState = Object.assign({}, wmWorkList);
    let task = newState[`${propState.actTask}`];
    let newWmNumber = null;
    if (task && task.wmlist && task.wmlist.length > 0) {
      const lastWMnumber = Math.max.apply(
        Math,
        task.wmlist.map((item) => item.wm_number)
      );
      newWmNumber = lastWMnumber + 1;
    } else {
      newWmNumber = 0;
    }

    const initialWaterMeterDatas = {
      id: null,
      version: null,
      is_new: false,
      watermeter_physical_place: selectedTask.current?.work_watermeter_physical_place || "",
      size: 15,
      meter_ok: true,
      act_meter: findTextValuesByViewID(content.workItems, "dismount_data_dismount_measurer_stateEt")[newWmNumber] || 0,
      meter_identifier: numOfFelszereles === 1 ? formatSealWatermeterId(findTextValuesByViewID(content.workItems, "dismount_data_serialNumberTv")[newWmNumber] || "") : "",
      plomba1_identifier: "",
      plomba1_ok: true,
      plomba2_identifier: "",
      plomba2_ok: true,
      images: [],
      note: "",
      t_id: propState.actTask,
      wm_number: newWmNumber,
      wm_note: "",
    };

    const updatedWaterMeterDatas = {
      ...initialWaterMeterDatas,
      is_new: true,
      is_cold: findTextValueIsColdByViewID(workItem, "mount_device_type_coldRb", "mount_device_type_warmRb"),
      size: findTextValuesByViewID(content.workItems, "Mérő mérete")[newWmNumber] || 15,
      act_meter: findTextValuesByViewID(content.workItems, "mount_measurer_valueEt")[newWmNumber] || 0,
      meter_identifier: formatSealWatermeterId(findTextValuesByViewID(content.workItems, "mount_serial_numberEt")[newWmNumber] || ""),
      plomba1_identifier: formatSealWatermeterId(findTextValuesByViewID(content.workItems, "mount_closing_element_numberEt")[newWmNumber] || ""),
    };

    if (newState[`${propState.actTask}`]) {
      if (newState[`${propState.actTask}`]["imgSerial"]) {
      } else {
        newState[`${propState.actTask}`]["imgSerial"] = 1;
      }
    } else {
      newState[`${propState.actTask}`] = { imgSerial: 1, wmlist: [] };
    }
    const tempList = newState[`${propState.actTask}`]["wmlist"]
      ? [...newState[`${propState.actTask}`].wmlist, initialWaterMeterDatas, updatedWaterMeterDatas]
      : [initialWaterMeterDatas, updatedWaterMeterDatas];

    newState[`${propState.actTask}`]["wmlist"] = tempList;
    return newState;
  };

  const findTextValuesByViewID = (content, viewID) => {
    const result = content
      .flat()
      .filter((element) => element.viewID === viewID)
      .map((element) => element?.textValue);
    return result;
  };

  const findTextValueIsColdByViewID = (workItem, coldId, warmId) => {
    let result = !(workItem.find((element) => element.viewID === warmId)?.textValue === "true");
    result = workItem.find((element) => element.viewID === coldId)?.textValue === "true";
    return result;
  };

  const findSingleTextValueByViewID = (content, viewID) => {
    const result = content.flat().find((element) => element.viewID === viewID)?.textValue;
    return result;
  };

  const addNewWatermeter = async (event) => {
    event.preventDefault();
    let newState = Object.assign({}, propState.wmWorkList);
    let task = newState[`${propState.actTask}`];
    let newWmNumber = null;
    if (task && task.wmlist && task.wmlist.length > 0) {
      const lastWMnumber = Math.max.apply(
        Math,
        task.wmlist.map((item) => item.wm_number)
      );
      newWmNumber = lastWMnumber + 1;
    } else {
      newWmNumber = 0;
    }

    const initialWaterMeterDatas = {
      id: null,
      version: null,
      is_new: false,
      is_cold: null,
      watermeter_physical_place: "",
      size: 15,
      meter_ok: true,
      act_meter: 0,
      meter_identifier: "",
      plomba1_identifier: "",
      plomba1_ok: true,
      plomba2_identifier: "",
      plomba2_ok: true,
      images: [],
      note: "",
      t_id: propState.actTask,
      wm_number: newWmNumber,
      wm_note: "",
    };

    const updatedWaterMeterDatas = {
      ...initialWaterMeterDatas,
      is_new: true,
    };

    if (newState[`${propState.actTask}`]) {
      if (newState[`${propState.actTask}`]["imgSerial"]) {
      } else {
        newState[`${propState.actTask}`]["imgSerial"] = 1;
      }
    } else {
      newState[`${propState.actTask}`] = { imgSerial: 1, wmlist: [] };
    }
    const tempList = newState[`${propState.actTask}`]["wmlist"]
      ? [...newState[`${propState.actTask}`].wmlist, initialWaterMeterDatas, updatedWaterMeterDatas]
      : [initialWaterMeterDatas, updatedWaterMeterDatas];

    newState[`${propState.actTask}`]["wmlist"] = tempList;
    propState.SetWmWorkList(newState);
  };

  const insertNewOther = (isPhoto = false) => {
    const tempVar = {
      id: null,
      version: null,
      description: isPhoto ? `Munkalap ${new Date().toLocaleString("hu", { timeZone: "Europe/Budapest" })}` : "",
      price: isPhoto ? types.INVOICE_ITEM_EXTREM_PRICE : 0,
      images: [],
      note: "",
      t_id: propState.actTask,
    };

    let newState = Object.assign({}, propState.otherWorkList);
    if (newState[`${propState.actTask}`]) {
      if (newState[`${propState.actTask}`]["imgSerial"]) {
      } else {
        newState[`${propState.actTask}`]["imgSerial"] = 1;
      }
    } else {
      newState[`${propState.actTask}`] = { imgSerial: 1, wmlist: [] };
    }
    const tempList = newState[`${propState.actTask}`]["wmlist"] ? [...newState[`${propState.actTask}`].wmlist, tempVar] : [tempVar];
    newState[`${propState.actTask}`]["wmlist"] = tempList;
    propState.SetOtherWorkList(newState);
  };

  const addNewOtherWork = async (event) => {
    event.preventDefault();
    insertNewOther();
  };

  const saveWorkSheetWithLoader = async (targetID, onSuccess) => {
    setLoading(true);
    setLoadingTarget(targetID);
    try {
      return await upLoadWorkSheet(propState,
        async () => {
          await onSuccess();
          setLoading(false);
          setLoadingTarget("");
        }
      );
    } finally {
      setLoading(false);
      setLoadingTarget("");
    }
  }
  const onSaveWorkSheet = async (targetID) => await saveWorkSheetWithLoader(targetID, () => setOverAllAcrdActiveKey(null));

  const overAllAccOnSelect = (evt) => {
    setOverAllAcrdActiveKey(evt);
    if (evt !== "1") {
      setActiveKey(null);
    }
  };

  const onClickCloseDetails = () => {
    showConfirmationDialog("Biztos, hogy ki akar lépni? A nem mentett adatok elveszhetnek.", "Részletező bezárása", (res) => onCloseDetails(res));
  };
  const onCloseDetails = (result) => {
    if (result === types.MESSAGEBOX_RESPONSE_YES) {
      propState.SetActTask(null);
      setContent(null);
    }
  };

  const emailWorksheet = async () => {
    const emailRetVal = await GetAnyList(taskURL + `/${propState.actTask}/mailworksheet/`);
    if (emailRetVal?.status === 200) {
      if (emailRetVal.data[0].status == "true") {
        toast.info(emailRetVal.data[0].body);
      } else {
        toast.warning(emailRetVal.data[0].body);
      }
    } else {
      if (emailRetVal?.status === 403) {
        propState.SetComStat(userStatus.unautorized);
      } else {
        propState.SetComStat(userStatus.offline);
      }
      showBackendError(backendErrorMessage("Hiba a munkalap küldésekor: ", emailRetVal.data[0].body));
      return;
    }
  };

  const InvoiceAccordionDisabled = () => {
    if (propState.actTaskState === propState.taskStateList.STARTED || propState.actTaskState === propState.taskStateList.SCHEDULED) {
      setOverAllAcrdActiveKey();
      toast.error("Kérlek zárd le a munkát számlázás előtt");
    }
  };

  const onSaveAndSendWorkSheet = () => {
    if (propState.comStat === userStatus.offline) {
      toast.warning(
        <div>
          Az alkalmazás offline állapontban van,
          <br /> nem lehetséges az email küldés
        </div>
      );
      return;
    }
    if (emailRef.current.value == null || emailRef.current.value == "") {
      toast.error("Kérlek töltsd ki az email címet!");
      return;
    }
    propState.addressData.contact_email = emailRef.current.value;
    propState.SetAddressData(propState.addressData);
    saveWorkSheetWithLoader(SAVE_AND_SEND_WORKSHEET, async () => await emailWorksheet());
  };

  const onSaveAndInvoice = () => {
    propState.addressData.contact_email = emailRef.current.value;
    propState.taskList.find((x) => x.task_id === propState.actTask).task_note_mech_to_disp = mechanicNoteRef.current.value;
    propState.SetAddressData(propState.addressData);
    propState.SetTaskList(propState.taskList);
    saveWorkSheetWithLoader(SAVE_AND_NEXT_TO_INVOICING, () => setOverAllAcrdActiveKey("3"));
  };

  const printWorkSheet = async () => {
    const printRetVal = await GetAnyList(taskURL + `/${propState.actTask}/worksheet/`, true /* isPdf */);
    if (printRetVal?.status === 200) {
      const pdfUrl = URL.createObjectURL(printRetVal.data);
      let link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `munkalap${propState.actTask}.pdf`;
      link.click();
    } else {
      if (printRetVal?.status === 403) {
        propState.SetComStat(userStatus.unautorized);
      } else {
        propState.SetComStat(userStatus.offline);
      }
      showBackendError(backendErrorMessage("Hiba a munkalap letöltésekor: ", printRetVal));
      return;
    }
  };

  const onSetActiveKey = (event) => {
    setActiveKey(event);
    //This function is neccessary to refresh tabs according to old and new watermeters on the watermeter accordions active sheet.
    if (
      event &&
      propState.wmWorkList[`${propState.actTask}`] !== undefined &&
      propState.wmWorkList[`${propState.actTask}`].wmlist.filter((item) => item.wm_number == event)[0] !== undefined
    ) {
      setActiveWmTabKey(propState.wmWorkList[`${propState.actTask}`].wmlist.filter((item) => item.wm_number == event)[0].is_new);
    }
    function delayedSetFocusRefKey() {
      setFocusRefKey(`${event}focus`);
    }
    setTimeout(delayedSetFocusRefKey, 500);
  };

  return (
    <>
      {initialLoading ? (
        <ReactModal
          isOpen={initialLoading}
          appElement={document.getElementById("root")}
          style={{
            width: "100%",
            height: "100%",
            margin: "0",
            padding: "0",
            dialog: {
              width: "100%",
              height: "100%",
              margin: "0",
              padding: "0",
            },
            content: {
              width: "100%",
              height: "auto",
              minHeight: "100%",
              inset: "0",
              borderRadius: "0",
              margin: "0",
              padding: "0",
            },
            // transform: 'translate(-50%, -50%)'
          }}
          contentLabel="Photo Modal"
        >
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Adatok betöltése...
          </div>
        </ReactModal>
      ) : (
        <>
          <table width="100%">
            <tbody>
              <tr>
                <td>
                  <Badge pill variant={propState.actTaskState.variant}>
                    {" "}
                    {propState.actTaskState.name}
                  </Badge>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Badge variant="info" size="sm">
                    {selectedTask.current.task_no}
                    <br></br>
                    {selectedTask.current.work_no}
                  </Badge>
                </td>
                <td style={{ textAlign: "right" }}>
                  <div style={{ textAlign: "right" }}>
                    <Button className=" px-1 py-0" variant="outline-dark" onClick={onClickCloseDetails}>
                      <h4 className="px-1 my-0 py-0">×</h4>
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <ControlButtons
            actTaskState={propState.actTaskState}
            SetActTaskState={(newState) => {
              propState.SetActTaskState(newState);
              setIsButtonDisabled(newState.key !== "STARTED");
            }}
            comStat={propState.comStat}
            actTask={propState.actTask}
            taskList={propState.taskList}
            taskStateList={propState.taskStateList}
            setOverAllAcrdActiveKey={setOverAllAcrdActiveKey}
            onSaveWorkSheet={(buttonTargetID, onSuccess) => saveWorkSheetWithLoader(buttonTargetID, async () => await onSuccess())}
            showOMEFileButton={true}
            disallowOMELoad={isAnySavedWaterMeterExists || isButtonDisabled}
            waterMeterList={propState.wmWorkList[`${propState.actTask}`]?.wmlist}
          />

          <Accordion defaultActiveKey={overAllAcrdActiveKey} activeKey={overAllAcrdActiveKey} onSelect={overAllAccOnSelect} width="100%" className="mx-0 px-0">
            <Card id="address_card">
              <Card.Header style={{ textAlign: "center", backgroundColor: addressFormValidated ? "#003399" : "red" }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  <h4 style={{ color: "#66ffff" }}>Cím</h4>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {overAllAcrdActiveKey === "0" && (
                    <>
                      <Table responsive>
                        <tbody>
                          <tr>
                            <td>{selectedTask.current.work_contact_name}</td>
                          </tr>
                          <tr>
                            <td>{selectedTask.current.work_contact_phone}</td>
                          </tr>
                          <tr>
                            <td>
                              {propState.addressData.address_city + ","} {propState.addressData.address_route + " "} {propState.addressData.address_street_number + ","}{" "}
                              {propState.addressData.address_building ? "épület" + " " + propState.addressData.address_building + " " : ""}
                              {propState.addressData.address_floor ? "emelet" + " " + propState.addressData.address_floor + " " : ""}
                              {propState.addressData.address_door ? "ajtó" + " " + propState.addressData.address_door : ""}
                              &nbsp;{propState.addressData.address_postal_code + " "} {propState.addressData.address_country}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h5 style={{ color: "aqua", textAlign: "center" }}>Cím részletek</h5>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <WmAddressForm
                        className="webAddressForm1234"
                        addressData={propState.addressData}
                        SetAddressData={propState.SetAddressData}
                        setAddressFormValidated={setAddressFormValidated}
                        onSave={(buttonTargetID) => saveWorkSheetWithLoader(buttonTargetID, () => setOverAllAcrdActiveKey("1"))}
                        actTask={propState.actTask}
                        taskList={propState.taskList}
                        SetTaskList={propState.SetTaskList}
                        ref={childRef}
                      />
                    </>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="worksheet_card">
              <Card.Header style={{ textAlign: "center", backgroundColor: "#003399" }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  <h4 style={{ color: "#66ffff" }}>Munkalap</h4>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {overAllAcrdActiveKey === "1" && (
                    <>
                      <div style={{ textAlign: "center" }} className="pt-2">
                        <h5 style={{ color: "aqua", textAlign: "center" }}>Munka adatok</h5>
                      </div>
                      {settings && (
                        <WorkSheetForm
                          SetTaskList={propState.SetTaskList}
                          taskList={propState.taskList}
                          actTask={propState.actTask}
                        />
                      )}
                      <Button key="newWmCreateBtn" variant="primary" onClick={addNewWatermeter} size="sm" className="mr-1 mb-1 py-0" disabled={isButtonDisabled}>
                        +<br />
                        Vízóra szerelés
                      </Button>
                      <Button key="newOtherCreateBtn" variant="primary" onClick={addNewOtherWork} size="sm" className="ml-1 mb-1 py-0" disabled={isButtonDisabled}>
                        +<br />
                        Egyéb munka
                      </Button>

                      <Accordion width="100%" activeKey={activeKey} onSelect={(e) => onSetActiveKey(e)}>
                        <>
                          <WmWorkListAcrdn
                            taskList={propState.taskList}
                            wmWorkList={propState.wmWorkList}
                            SetWmWorkList={propState.SetWmWorkList}
                            actTask={propState.actTask}
                            activeKey={activeKey}
                            setActiveKey={setActiveKey}
                            activeWmTabKey={activeWmTabKey}
                            setActiveWmTabKey={setActiveWmTabKey}
                            focusRefList={focusRefList}
                            key="wmaccrdn"
                            sealList={propState.sealList}
                            wmList={propState.wmList}
                            propStateListWmItem={propState.propStateListWmItem}
                          />
                          <OtherWorkListAcrdn
                            taskList={propState.taskList}
                            otherWorkList={propState.otherWorkList}
                            SetOtherWorkList={propState.SetOtherWorkList}
                            wmWorkList={propState.wmWorkList}
                            SetWmWorkList={propState.SetWmWorkList}
                            actTask={propState.actTask}
                            activeKey={activeKey}
                            setActiveKey={setActiveKey}
                            focusRefList={focusRefList}
                            propStateListWmItem={propState.propStateListWmItem}
                            key="otheraccrdn"
                          />
                        </>
                      </Accordion>

                      {activeKey && (
                        <div>
                          <Button key="newWmCreateBtnLow" variant="primary" onClick={addNewWatermeter} size="sm" className="mr-1 mb-1 py-0" disabled={isButtonDisabled}>
                            +<br />
                            Vízóra szerelés
                          </Button>
                          <Button key="newOtherCreateBtnLow" variant="primary" onClick={addNewOtherWork} size="sm" className="ml-1 mb-1 py-0" disabled={isButtonDisabled}>
                            +<br />
                            Egyéb munka
                          </Button>
                        </div>
                      )}
                      {propState.actTaskState === propState.taskStateList.STARTED && (
                        <Button variant="info" onClick={() => onSaveWorkSheet(SAVE_WORKSHEET)} className="my-2" disabled={loading}>
                          <div>
                            Mentés
                            {loading && loadingTarget === SAVE_WORKSHEET && (
                              <ButtonLoader />)}
                          </div>
                        </Button>
                      )}
                    </>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="ws_photo_card">
              <Card.Header style={{ textAlign: "center", backgroundColor: "#003399" }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  <h4 style={{ color: "#66ffff" }}>Munkalap lezárása </h4>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  {(propState.actTaskState === propState.taskStateList.FINISHED || propState.actTaskState === propState.taskStateList.FAILED) && (
                    <Button className="px-2 my-2 mx-4" onClick={printWorkSheet}>
                      {" "}
                      Munkalap nyomtatás
                    </Button>
                  )}
                  {overAllAcrdActiveKey === "2" && workSheetPhotoItemSerial !== null && (
                    <>
                      <Accordion className="mb-2">
                        <Card>
                          <Accordion.Toggle as={Card.Header} eventKey="0" style={{ color: "aqua", textAlign: "left", fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }}>
                            Kézzel aláírt munkalap és fotó feltöltése
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <WorkSheetPhoto
                                taskList={propState.taskList}
                                otherWorkList={propState.otherWorkList}
                                SetOtherWorkList={propState.SetOtherWorkList}
                                actTask={propState.actTask}
                                item={workSheetPhotoItemSerial}
                                setItem={setWorkSheetPhotoItemSerial}
                                overAllAcrdActiveKey={overAllAcrdActiveKey}
                                setOverAllAcrdActiveKey={setOverAllAcrdActiveKey}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                      <ControlButtons
                        actTaskState={propState.actTaskState}
                        SetActTaskState={(newState) => {
                          propState.SetActTaskState(newState);
                          setIsButtonDisabled(newState.key !== "STARTED");
                        }}
                        comStat={propState.comStat}
                        actTask={propState.actTask}
                        taskList={propState.taskList}
                        taskStateList={propState.taskStateList}
                        setOverAllAcrdActiveKey={setOverAllAcrdActiveKey}
                        onSaveWorkSheet={(buttonTargetID, onSuccess) => saveWorkSheetWithLoader(buttonTargetID, async () => await onSuccess())}
                        showOMEFileButton={false}
                        disallowOMELoad={isAnySavedWaterMeterExists || isButtonDisabled}
                        waterMeterList={propState.wmWorkList[`${propState.actTask}`]?.wmlist}
                      />
                      {(propState.actTaskState === propState.taskStateList.FINISHED || propState.actTaskState === propState.taskStateList.FAILED) && (
                        <Button variant="info" onClick={() => saveWorkSheetWithLoader(SAVE_SIGNED_WORKSHEET, () => setOverAllAcrdActiveKey(null))} className="ml-4 mb-2" disabled={loading}>
                          <div>Aláírt munkalap rögzítése
                            {loading && loadingTarget === SAVE_SIGNED_WORKSHEET && (
                              <ButtonLoader />)}
                          </div>
                        </Button>
                      )}
                      <SignatureBrief taskList={propState.taskList} actTask={propState.actTask} SetComStat={propState.SetComStat} />

                      <div style={{ textAlign: "center" }} className="pt-2">
                        <h5 style={{ color: "aqua", textAlign: "center" }}>Munkalap küldése emailben</h5>
                      </div>
                      <Form>
                        <Form.Group controlId="contactEmail">
                          <Form.Label>
                            <small style={{ color: "indigo", textAlign: "left" }}>Email:</small>
                          </Form.Label>
                          <Form.Control placeholder="Email" defaultValue={propState.addressData.contact_email} name="contactEmail" ref={emailRef} disabled={!(propState.actTaskState === propState.taskStateList.FINISHED || propState.actTaskState === propState.taskStateList.FAILED)} />
                        </Form.Group>
                        <Button variant="info" className="ml-4 mb-2" onClick={onSaveAndSendWorkSheet} disabled={!(propState.actTaskState === propState.taskStateList.FINISHED || propState.actTaskState === propState.taskStateList.FAILED) || loading}>
                          <div>Munkalap küldése emailben
                            {loading && loadingTarget === SAVE_AND_SEND_WORKSHEET && (
                              <ButtonLoader />)}
                          </div>
                        </Button>
                        <Accordion defaultActiveKey="0">
                          <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0" style={{ color: "aqua", textAlign: "left", fontSize: "1rem", fontWeight: "bold", cursor: "pointer" }}>
                              Szerelő megjegyzése a Diszpécser felé
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <Form.Group controlId="mechanicNote">
                                  <Form.Control
                                    placeholder="Szerelő megjegyzése a Diszpécser felé"
                                    defaultValue={selectedTask.current.task_note_mech_to_disp}
                                    name="mechanicNote"
                                    ref={mechanicNoteRef}
                                  />
                                </Form.Group>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Form>

                      <Button
                        className="ml-4 mb-2 mt-2"
                        onClick={onSaveAndInvoice}
                        disabled={!(propState.actTaskState === propState.taskStateList.FINISHED || propState.actTaskState === propState.taskStateList.FAILED) || loading}
                      >
                        <div>Mentés és tovább a számlázásra
                          {loading && loadingTarget === SAVE_AND_NEXT_TO_INVOICING && (
                            <ButtonLoader />)}
                        </div>
                      </Button>
                    </>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card id="invoice_card" onClick={InvoiceAccordionDisabled}>
              <Card.Header style={{ textAlign: "center", backgroundColor: "#003399" }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  <h4 style={{ color: "#66ffff" }}>Számlázási adatok ellenőrzése</h4>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  {overAllAcrdActiveKey === "3" && (
                    <>
                      <div style={{ textAlign: "center" }} className="pt-2">
                        <h5 style={{ color: "aqua", textAlign: "center" }}>Számla adatok</h5>
                      </div>
                      <WmInvoiceForm
                        invoiceData={propState.invoiceData}
                        setInvoiceData={propState.setInvoiceData}
                        setInvoiceFormValidated={setInvoiceFormValidated}
                        setOverAllAcrdActiveKey={setOverAllAcrdActiveKey}
                        actTask={propState.actTask}
                        taskList={propState.taskList}
                        SetTaskList={propState.SetTaskList}
                        comStat={propState.comStat}
                        SetComStat={propState.SetComStat}
                        onSave={(buttonTargetID) => saveWorkSheetWithLoader(buttonTargetID, () => setOverAllAcrdActiveKey("4"))}
                      />
                    </>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card onClick={InvoiceAccordionDisabled}>
              <Card.Header style={{ textAlign: "center", backgroundColor: "#003399" }}>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  <h4 style={{ color: "#66ffff" }}>Számlázás</h4>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  {overAllAcrdActiveKey === "4" && (
                    <>
                      <div style={{ textAlign: "center" }} className="pt-2">
                        <h5 style={{ color: "aqua", textAlign: "center" }}>Számla adatok</h5>
                      </div>
                      <WmInvoiceSummary
                        propStateTaskListActTask={selectedTask}
                        addressData={propState.addressData}
                        invoiceData={propState.invoiceData}
                        otherWorkList={propState.otherWorkList}
                        actTask={propState.actTask}
                        taskList={propState.taskList}
                        setOverAllAcrdActiveKey={setOverAllAcrdActiveKey}
                        comStat={propState.comStat}
                        SetComStat={propState.SetComStat}
                      />
                    </>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </>
      )}
    </>
  );
};
export default DetailedTask;
