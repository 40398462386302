import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import * as types from "../shared/types";
import { userStatus, apiURL, PostAnyRec, PatchAnyRec, GetAnyList, showConfirmationDialog } from "./WmUtils";
import { showBackendError } from "./ShowError";
import { backendErrorMessage } from "../shared/backendError";
import { toast } from "react-toastify";
import useInvoiceStatusStore from "../stores/useInvoiceStatusStore.jsx";

const WmInvoiceSummary = (props) => {
  const [propState, setPropState] = useState(props);
  const setInvoiceStatus = useInvoiceStatusStore((state) => state.setInvoiceStatus);

  const isMountedComponent = useRef(true);

  useEffect(() => {
    if (isMountedComponent.current) {
      setPropState(props);
    }
    return () => {
      isMountedComponent.current = false;
    };
  }, [props]);

  const total = () => {
    let sumOfOrder = parseInt(propState.propStateTaskListActTask.current.work_price);
    propState.otherWorkList[propState.actTask]?.wmlist.map((otherWork) => {
      if (otherWork.price !== types.INVOICE_ITEM_EXTREM_PRICE) {
        sumOfOrder += parseInt(otherWork.price);
      }
      return;
    });
    return sumOfOrder;
  };

  const getTaskInvoiceServerStatus = async () => {
    const invoiceStatusRetVal = await GetAnyList(`${apiURL}/tasks/${propState.actTask}/`);
    if (invoiceStatusRetVal?.status === 200) {
      // changed to check for undefined instead,
      // because if the invoice_status was set to 0, then the error message was shown...
      if (invoiceStatusRetVal.data.invoice_status !== undefined) {
        propState.propStateTaskListActTask.current.task_invoice_status = invoiceStatusRetVal.data.invoice_status;
        setInvoiceStatus(propState.propStateTaskListActTask.current.task_invoice_status);
        toast.info("Számla státusz lekérdezve!");
      } else {
        toast.warning("Nem sikerült a státusz lekérdezés!");
      }
    } else {
      if (invoiceStatusRetVal?.status === 403) {
        propState.SetComStat(userStatus.unautorized);
      } else {
        propState.SetComStat(userStatus.offline);
      }
      showBackendError(backendErrorMessage("Hiba a számla státusz lekérdezésekor: ", invoiceStatusRetVal.data[0].body));
      return;
    }
  };

  const getOtherWorkList = () => {
    let result = propState.otherWorkList[propState.actTask]?.wmlist.map((otherWork, index) => {
      if (otherWork.price !== types.INVOICE_ITEM_EXTREM_PRICE) {
        return (
          <tr className="tr" key={index}>
            <td className="td">{otherWork.description}</td>
            <td id="otherPrice" className="td">
              {Math.round(otherWork.price)} Ft
            </td>
          </tr>
        );
      }
    });
    return result;
  };

  const onInvoiceClick = async (paymentMethod) => {
    await getTaskInvoiceServerStatus();
    if (propState.propStateTaskListActTask.current.task_invoice_status === types.INVOICE_STATUS_TRANSFER_INVOICE_REQUIRED_BY_MECHANIC) {
      showConfirmationDialog(
        "Biztos, hogy ki szeretné állítani a számlát? Utalásos számlaigény kérésére ezután nem lesz lehetőség!",
        "Utalásos számlaigény visszaállítása",
        (res) => onCloseDetails(res, paymentMethod)
      );
    } else {
      invoiceIssue(paymentMethod);
    }
  };

  const onCloseDetails = (result, newPaymentMethod) => {
    if (result === types.MESSAGEBOX_RESPONSE_YES) {
      transferInvoiceRequest(types.INVOICE_STATUS_NOT_INVOICED_YET, newPaymentMethod);
    }
  };

  const invoiceIssue = async (paymentMethod) => {
    let invRetVal = undefined;
    const invUrl = `${apiURL}/tasks/${propState.actTask}/invoice/`;
    invRetVal = await PostAnyRec(
      invUrl,
      {
        payment_method: paymentMethod,
      },
      true /*isPdf */
    );
    if (invRetVal?.status === 200 || invRetVal?.status === 201) {
      const pdfUrl = URL.createObjectURL(invRetVal.data);
      let link = document.createElement("a");
      link.href = pdfUrl;
      link.download = JSON.stringify(invRetVal.headers).match(/(invoice.W_.*?.pdf)/g);
      link.click();
      setInvoiceStatus(types.INVOICE_STATUS_GENERATED);
    } else {
      if (invRetVal?.status === 403) {
        propState.SetComStat(userStatus.unautorized);
      } else {
        propState.SetComStat(userStatus.offline);
      }
      invRetVal?.data.text().then((invRetValStr) => {
        try {
          const invRetValData = JSON.parse(invRetValStr);
          if (!!invRetValData?.invoice?.details && "szamlazz.hu" in invRetValData.invoice.details && !!invRetValData.invoice.details["szamlazz.hu"].error_message) {
            showBackendError(backendErrorMessage("Hiba a számla letöltésekor: <br>" + invRetValData.invoice.details["szamlazz.hu"].error_message));
          } else {
            showBackendError(
              backendErrorMessage(
                "Hiba a számla letöltésekor: ",
                invRetVal,
                Object.keys(invRetValData).map((prop) => invRetValData[prop])
              )
            );
          }
        } catch {
          toast.error("Hibás számlaformátum!");
        }
      });
    }
  };

  const transferInvoiceRequest = async (statusNumber, paymentMethod) => {
    await getTaskInvoiceServerStatus();
    if (propState.propStateTaskListActTask.current.task_invoice_status === types.INVOICE_STATUS_GENERATED) {
      toast.error("Ehhez a feladathoz készült már számla!");
    } else {
      let returnValue = undefined;
      const invUrl = `${apiURL}/tasks/${propState.actTask}/`;
      returnValue = await PatchAnyRec(invUrl, {
        invoice_status: statusNumber,
        payment_method: paymentMethod,
      });
      if (returnValue?.status === 200 || returnValue?.status === 201) {
        if (statusNumber === types.INVOICE_STATUS_TRANSFER_INVOICE_REQUIRED_BY_MECHANIC) {
          toast.success("Utalásos számlaigény jelezve!");
        }
        if (statusNumber === types.INVOICE_STATUS_NOT_INVOICED_YET) {
          toast.success("Utalásos számlaigény visszavonva, számlázás folyamatban!");
          invoiceIssue(paymentMethod);
        }
      } else {
        if (returnValue?.status === 403) {
          propState.SetComStat(userStatus.unautorized);
        } else {
          propState.SetComStat(userStatus.offline);
        }
      }
    }
  };

  return (
    <div>
      <p>
        <strong>Ügyfél neve:</strong> {propState.invoiceData.tax_number === "-" ? propState.addressData.contact_name : propState.invoiceData.contact_name}
      </p>
      <p>
        <strong>Ügyfél címe: </strong>
        {propState.addressData.address_postal_code} {propState.addressData.address_city}
      </p>
      <p>
        {propState.addressData.address_route} {propState.addressData.address_street_number + " "}{" "}
        {propState.addressData.address_building ? "épület " + propState.addressData.address_building + " " : ""}
        {propState.addressData.address_floor ? "emelet " + propState.addressData.address_floor + " " : ""}
        {propState.addressData.address_door ? "ajtó " + propState.addressData.address_door : ""}{" "}
        {propState.addressData.address_doorbell ? "csengő " + propState.addressData.address_doorbell : ""}
      </p>
      <p>
        <strong>Ügyfél számlázási címe: </strong>
        {propState.invoiceData.address_postal_code} {propState.invoiceData.address_city}
      </p>
      <p>
        {propState.invoiceData.address_route} {propState.invoiceData.address_street_number + " "}{" "}
        {propState.invoiceData.address_building ? "épület " + propState.invoiceData.address_building + " " : ""}
        {propState.invoiceData.address_floor ? "emelet " + propState.invoiceData.address_floor : ""}
        {propState.invoiceData.address_door ? "ajtó " + propState.invoiceData.address_door : ""}{" "}
        {propState.invoiceData.address_doorbell ? "csengő " + propState.invoiceData.address_doorbell : ""}
      </p>
      <p>
        <strong>Email: </strong> {propState.addressData.contact_email}
      </p>
      <p>
        <strong>Adószám: </strong>
        {propState.invoiceData.tax_number}
      </p>
      <table id="table">
        <thead>
          <tr className="tr">
            <th className="th" scope="col">
              Megnevezés
            </th>
            <th className="th" scope="col">
              Ár
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="tr">
            <td className="td">
              Vízóra: {propState.propStateTaskListActTask.current.jobtype_name}
              ,&nbsp;{propState.propStateTaskListActTask.current.job_quantity} db
            </td>
            <td id="workPrice" className="td">
              {Math.round(propState.propStateTaskListActTask.current.work_price)} Ft
            </td>
          </tr>
          {getOtherWorkList()}
          <tr className="tr">
            <td className="td">
              <strong>Összesen:</strong>
            </td>
            <td className="td">
              <strong>{total()} Ft</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="invoiceSummaryButtons">
        <Button variant="primary" onClick={() => propState.setOverAllAcrdActiveKey("3")}>
          Vissza
        </Button>
        <Button variant="primary" onClick={() => transferInvoiceRequest(types.INVOICE_STATUS_TRANSFER_INVOICE_REQUIRED_BY_MECHANIC, types.INVOICE_PAYMENT_TRANSFER)}>
          Utalásos számla igénylése
        </Button>
        <DropdownButton
          title={propState.addressData.contact_email ? "Számla kiállítása és küldése emailben" : "Számla kiállítása (nincs email)"}
          onSelect={async (eventKey) => onInvoiceClick(eventKey)}
        >
          <Dropdown.Item key={types.INVOICE_PAYMENT_CASH} eventKey={types.INVOICE_PAYMENT_CASH}>
            Készpénz
          </Dropdown.Item>
          <Dropdown.Item key={types.INVOICE_PAYMENT_BANK_CARD} eventKey={types.INVOICE_PAYMENT_BANK_CARD}>
            Bankkártya
          </Dropdown.Item>
        </DropdownButton>
      </div>
    </div>
  );
};
export default WmInvoiceSummary;
