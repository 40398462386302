import React, { useState, useEffect, useRef } from 'react';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { CheckConnection,  apiURL, GetToken} from './WmUtils'

import backendErrorMessage from '../shared/backendError';
import { showBackendError } from './ShowError';
import { toast } from 'react-toastify';

let URL = apiURL + '/token-auth/';




const WmFuncBrief = props => {
    const [propState, setPropState] = useState(props);
    const [show, setShow] = useState(false);
    useEffect(() => {
        setPropState(props);
    }, [props]);

    const buttonCallBack = () => setShow(false);

    const setShowCbk = toShow => {
        setShow(toShow);
    }


    if (show) {
        return (
            <WmPureLogin selectionCallBack={propState.selectionCallBack}
                prevSelection={propState.prevSelection}
                SetComStat={propState.SetComStat}
                buttonCallBack={buttonCallBack}

            />
        );
    }
    return (
        <Button variant="info" onClick={() => setShowCbk(true)}>
            Bejelentkezés űrlap
        </Button>
    );
}

export const WmFuncLogin = props => {
    const [propState, setPropState] = useState(props);

    useEffect(() => {
        setPropState(props);
    }, [props]);

    return (
        <Container className="p-2">
            <Jumbotron className="pb-1">
                <h1 className="header">Bejelentkezés</h1>

                <WmPureLogin {...propState} />
                <hr />

            </Jumbotron>
        </Container>
    );

}

export const WmPureLogin = props => {
    const [propState, setPropState] = useState(props);

    useEffect(() => {
        setPropState(props);
    }, [props]);

    const nameRef = useRef();
    const pwRef = useRef();

    const onFormSubmit = async (event) => {
        event.preventDefault();
        const creds = { "username": nameRef.current.value, "password": pwRef.current.value };

        const wm_token = await GetToken(URL, creds);
        if (wm_token?.token) {
            localStorage.setItem("wm_auth_token", wm_token.token);
            localStorage.setItem("wm_auth_user", nameRef.current.value);
            console.log("response: ------------> " + wm_token.token);

        } else {
            if (wm_token?.response?.data?.non_field_errors) {
                toast.warn(wm_token.response.data.non_field_errors[0]);
            } else if(wm_token?.response?.data?.username || wm_token?.response?.data?.password){

                showBackendError(backendErrorMessage("Nem sikerült bejelentkezni:",wm_token.response));
                return;
            }else{

                    toast.warn("Az alkalmazás offline állapontban van, jelenleg nem tud bejelentkezni");
                return;

            }
            localStorage.removeItem("wm_auth_user");
            localStorage.removeItem("wm_auth_token");
        }
        await propState.SetComStat(await CheckConnection());
        propState.selectionCallBack(propState.prevSelection);
        if (propState.hasOwnProperty('buttonCallBack')) {
            propState.buttonCallBack();
        }
    }
    return (
        <Form onSubmit={onFormSubmit}>
            <Form.Group controlId="formBasicUserName">
                <Form.Label>Felhasználónév megadása:</Form.Label>
                <Form.Control placeholder="Felhasználónév..." name="loginusername" ref={nameRef} />
                <Form.Text className="text-muted">
                    Ha nem tudja a felhasználónevét, kérje el a munkaadójától.
        </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
                <Form.Label>Jelszó megadása:</Form.Label>
                <Form.Control type="password" placeholder="Jelszó..." name="loginpassword" ref={pwRef} />
            </Form.Group>
            <Button variant="primary" type="submit" >
                Bejelentkezés
    </Button>
        </Form>
    );
}

const Login = props => {
    const [propState, setPropState] = useState(props);

    useEffect(() => {
        setPropState(props);
    }, [props]);


    return (
        <Container className="p-2">
            <Jumbotron >
                <h1 className="header">Bejelentkezés</h1>

                <WmFuncBrief
                    selectionCallBack={propState.selectionCallBack}
                    prevSelection={'Worklist'}
                    SetComStat={propState.SetComStat}
                    comStat={propState.comStat}
                />
                <hr />

            </Jumbotron>
        </Container>
    );
}

export default Login;
