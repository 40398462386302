
import React, { useState, useRef, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';
import * as types from "../shared/types";
import useTaskDetailsFormControlStore from '../stores/TaskDetailsFormControlStore';

const OtherItemForm = props => {
    const [propState, setPropState] = useState(props);

    const descRef = useRef();
    const priceRef = useRef();
    const noteRef = useRef();
    const propStateListActTaskItem = useRef(props.otherWorkList[`${props.actTask}`].wmlist[props.item]);
    const isMountedComponent = useRef(true);

    const {isButtonDisabled} = useTaskDetailsFormControlStore((state) => ({
        isButtonDisabled: state.isButtonDisabled
    }));

    useEffect(() => {
        if (isMountedComponent.current) {
            setPropState(props);
            propStateListActTaskItem.current = props.otherWorkList[`${props.actTask}`].wmlist[props.item];
        }
        return () => {
            isMountedComponent.current = false; };
    }, [props]);

/*eslint-disable eqeqeq*/
    const onInputChange = async (event) => {
        event.preventDefault();
        if(priceRef.current.value==types.INVOICE_ITEM_EXTREM_PRICE){
            toast.warning("Ez az érték itt nem megengedett");
            return;
        }
        let tempInvItem = Object.assign({}, propState.otherWorkList);
        let tmpInvWmListItem = tempInvItem[`${propState.actTask}`].wmlist[propState.item];
        tmpInvWmListItem.description = descRef.current.value;
        tmpInvWmListItem.price = priceRef.current.value;
        tmpInvWmListItem.note = noteRef.current.value;

        propState.SetOtherWorkList(tempInvItem);

    }

    return (
        <Form
        key={propState.keyProp+ 'otherform'}
         className='focus_selector'>
            <Form.Group controlId="cdesc">
                <Form.Label
         key={propState.keyProp +'focus'}
          ref={focref => {if(!!focref)
            propState.focusRefList.current[propState.keyProp + 'focus']=focref;
        }}><small style={{ color: "indigo", textAlign: "left" }}>Tétel leírása:</small></Form.Label>
                <Form.Control disabled={isButtonDisabled} placeholder="Tétel leírása" onBlur={onInputChange} defaultValue={propStateListActTaskItem.current.description} name="desc" ref={descRef} />
            </Form.Group>
            <Form.Group controlId="cprice">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Ár:</small></Form.Label>
                <Form.Control disabled={isButtonDisabled} type="number" placeholder="Ár" onBlur={onInputChange} defaultValue={propStateListActTaskItem.current.price} name="price" ref={priceRef} />
            </Form.Group>
            <Form.Group controlId="cnote">
                <Form.Label><small style={{ color: "indigo", textAlign: "left" }}>Szerelő megjegyzése az ügyfél felé:</small></Form.Label>
                <Form.Control disabled={isButtonDisabled} as="textarea" rows="3" placeholder="Megjegyzés" onBlur={onInputChange} defaultValue={propStateListActTaskItem.current.note} name="note" ref={noteRef} />
            </Form.Group>
        </Form>
    );
}
export default OtherItemForm;
