import { useEffect, useRef } from "react";
import axios from "axios";
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const apiURLRoot = process.env.REACT_APP_WM_BASE_PATH ? `${process.env.REACT_APP_WM_BASE_PATH}` : isLocalhost ? "http://127.0.0.1:8000" : "";
export const apiURL = apiURLRoot + "/api";
export const userStatus = Object.freeze(require("./user_status_enum"));

const timeoutNormal = 40000; //increased the timeout to 40sec
const timeoutSlow = 60000;

export const GetHead = async (url, token) => {
  const http = require("stream-http");
  const https = require("https-browserify");

  const headAxios = axios.create({
    // Extremely important to be set lower or close to the
    // useInterval window, otherwise toasts are being
    // jammed when internet connection returns
    timeout: timeoutNormal,

    //keepAlive pools and reuses TCP connections, so it's faster
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),

    maxRedirects: 3,
    maxContentLength: 1024,
  });
  let retVal = null;
  if (window.navigator.onLine) {
    await headAxios
      .head(url, {
        headers: {
          Authorization: `Token ${token}`,
        },
        cancelToken: new axios.CancelToken(function executor(cancel) {
          setTimeout(() => {
            cancel();
          }, timeoutNormal);
        }),
      })
      .catch((err) => {
        if (err.response) {
          switch (err.response.status) {
            case 401:
              retVal = { data: userStatus.nack };
              break;
            case 403:
              retVal = { data: userStatus.unauthorized };
              break;
            case 404:
              retVal = { data: userStatus.offline };
              break;
            default:
              retVal = { data: userStatus.offline };
              break;
          }
        } else {
          retVal = { data: userStatus.offline };
        }
      });
  } else {
    retVal = { data: userStatus.offline };
  }
  return retVal ? retVal : { data: userStatus.ok };
};

export const CheckConnection = async () => {
  let retVal = userStatus.undefined;
  let URL = apiURL + "/settings/";
  let token = localStorage.getItem("wm_auth_token");
  if (token === null) {
    return userStatus.logged_out;
  }
  const { data } = await GetHead(URL, token);
  if (data?.name === "ok") {
    retVal = userStatus.ok;
    retVal.username = localStorage.getItem("wm_auth_user");
    return retVal;
  }
  return data;
};

export const GetAnyList = async (url, isPdf = false) => {
  const retVal = await axios
    .get(url, {
      headers: {
        Authorization: `Token ${localStorage.getItem("wm_auth_token")}`,
      },
      responseType: isPdf ? "blob" : null,
      cancelToken: new axios.CancelToken(function executor(cancel) {
        setTimeout(() => {
          cancel();
        }, timeoutNormal);
      }),
    })
    .catch((err) => err.response);
  return retVal;
};

export const openPdf = async (url) => {
  await axios
    .get(url, {
      headers: {
        Authorization: `Token ${localStorage.getItem("wm_auth_token")}`,
      },
      responseType: "blob",
      cancelToken: new axios.CancelToken(function executor(cancel) {
        setTimeout(() => {
          cancel();
        }, timeoutNormal);
      }),
    })
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const pdfWindow = window.open();
      pdfWindow.location.href = fileURL;
    })
    .catch((err) => err.response);
};

export const PostAnyRec = async (url, data, isPdf = false) => {
  const retVal = await axios
    .post(url, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("wm_auth_token")}`,
      },
      responseType: isPdf ? "blob" : null,
      cancelToken: new axios.CancelToken(function executor(cancel) {
        setTimeout(() => {
          cancel();
        }, timeoutNormal);
      }),
    })
    .catch((err) => err.response);
  return retVal;
};

export const PatchAnyRec = async (url, data) => {
  const retVal = await axios
    .patch(url, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("wm_auth_token")}`,
      },
      cancelToken: new axios.CancelToken(function executor(cancel) {
        setTimeout(() => {
          cancel();
        }, timeoutNormal);
      }),
    })
    .catch((err) => err.response);
  return retVal;
};

export const PutAnyRec = async (url, data) => {
  const retVal = await axios
    .put(url, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("wm_auth_token")}`,
      },
      cancelToken: new axios.CancelToken(function executor(cancel) {
        setTimeout(() => {
          cancel();
        }, timeoutNormal);
      }),
    })
    .catch((err) => err.response);
  return retVal;
};

export const GetToken = async (url, creds) => {
  let config = {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    cancelToken: new axios.CancelToken(function executor(cancel) {
      setTimeout(() => {
        cancel();
      }, timeoutNormal);
    }),
    url: url,
    data: creds,
  };
  const retVal = await axios(config).catch((error) => error);
  if (retVal?.data?.token) return retVal.data;
  else return retVal;
};

export const setTaskStateApiCbk = async (url) => {
  return await axios
    .post(
      url,
      {},
      {
        headers: {
          Authorization: `Token ${localStorage.getItem("wm_auth_token")}`,
        },
        cancelToken: new axios.CancelToken(function executor(cancel) {
          setTimeout(() => {
            cancel();
          }, timeoutNormal);
        }),
      }
    )
    .catch((err) => err.response);
};

export const GetImage = async (url) => {
  const retVal = await axios
    .get(url, {
      headers: {
        Authorization: `Token ${localStorage.getItem("wm_auth_token")}`,
      },
      responseType: "blob",
      cancelToken: new axios.CancelToken(function executor(cancel) {
        setTimeout(() => {
          cancel();
        }, timeoutSlow);
      }),
    })
    .catch((err) => err.response);
  return retVal;
};

export const PostPhoto = async (url, data) => {
  const retVal = await axios
    .post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${localStorage.getItem("wm_auth_token")}`,
      },
      cancelToken: new axios.CancelToken(function executor(cancel) {
        setTimeout(() => {
          cancel();
        }, timeoutSlow);
      }),
    })
    .catch((err) => err.response);
  return retVal;
};

//confirmdialog --- modal
export var messageBoxInstance = undefined;

export const setMessageBoxInstance = (instance) => {
  messageBoxInstance = instance;
};

export const showConfirmationDialog = (message, title, callbackFunction) => {
  return messageBoxInstance.showMessage(message, title, callbackFunction);
};

// Allow only digits and no leading zeros
export const formatSealWatermeterId = (str) => str ? str.replace(/\D/g, '').replace(/^0+/, '') : str;
