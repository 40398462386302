import React from 'react';

import { toast } from 'react-toastify';

export const showError = (errorMessage) => {
    toast.error(errorMessage);
  }


  export const showBackendError = (message) => {
    showError(
      <div
        dangerouslySetInnerHTML={{
          __html: message
        }}>
      </div>);
  }
