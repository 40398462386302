import backendErrorMessage from "../shared/backendError";
import { showBackendError } from "../app/ShowError";
import { apiURL, GetAnyList, GetImage } from "../app/WmUtils";
import * as types from "../shared/types";

/*eslint-disable eqeqeq*/
const taskURL = apiURL + "/tasks";

export const fetchInitialItems = async (propState, waterMeters, seals) => {
  let newState = undefined;

  const fetchListFromDB = async (apiItemStr) => {
    await GetAnyList(`${taskURL}/${propState.actTask}${apiItemStr}`).then(async (retVal) => {
      if (retVal?.status == 200 && retVal?.data) {
        /// Now let's check if there are items for the recent task in database:
        if (retVal.data.length) {
          newState = apiItemStr === "/wmeters/" ? Object.assign({}, propState.wmWorkList) : Object.assign({}, propState.otherWorkList);
          /// If it was a virgin state, let's add the first item
          if (newState[`${propState.actTask}`]) {
            if (!newState[`${propState.actTask}`]["imgSerial"]) {
              newState[`${propState.actTask}`]["imgSerial"] = 1;
            }
          } else {
            newState[`${propState.actTask}`] = { imgSerial: 1, wmlist: [] };
          }

          let newStateActTask = newState[`${propState.actTask}`];
          /// If there are records of recent task stored in our local react state, and have id, then collect those id-s
          const idStore = newStateActTask.wmlist.filter((iii) => iii.id).map((iii) => iii.id);

          /// Collect the records those are exclusively in db. The others to be neglected so overwritten by local data
          let filteredData = retVal.data.filter((iii) => !idStore.includes(iii.id));

          /// Add the above filtered records to our local state
          filteredData = await Promise.all(
            filteredData.map(async (filtii) => {
              filtii.images = await Promise.all(
                filtii.image_filenames.map(
                  async (filename) =>
                    await GetImage(`${apiURL}/tasks/${propState.actTask}${apiItemStr}${filtii.id}/photos/${filename}`).then((retVal) => ({
                      name: filename,
                      image: URL.createObjectURL(retVal.data),
                    }))
                )
              );
              return filtii;
            })
          );
          newStateActTask.wmlist = [...newStateActTask.wmlist, ...filteredData];

          /// Update the image name lists with the newcomer data
          newStateActTask.wmlist = newStateActTask.wmlist.map((task_item) => {
            if (task_item["images"]) {
              let imgList = task_item.images.map((imgJson) => imgJson.name);
              task_item.image_filenames = imgList;
            }
            return task_item;
          });

          /// If there were images on the server, re-set the file-naming serial number based on filenames:
          const max_idx =
            newStateActTask.wmlist.reduce((max, iii) => {
              const max_act = iii.image_filenames?.reduce((max_iii, jjj) => {
                const max_loc = !!jjj?.length ? parseInt(jjj.match(/\d+/g)[2]) : 1;
                max_iii = max_loc > max_iii ? max_loc : max_iii;
                return max_iii;
              }, 1);
              return max_act > max ? max_act : max;
            }, 1) + 1; // Plus one, because this is the stored file's number, not the increased one
          newStateActTask["imgSerial"] = newStateActTask["imgSerial"] < max_idx ? max_idx : newStateActTask["imgSerial"];
          if (apiItemStr === "/wmeters/") {
            propState.SetWmWorkList(newState);
          } else {
            propState.SetOtherWorkList(newState);
          }
        }
      } else {
        showBackendError(backendErrorMessage(`Hiba ${apiItemStr === "/wmeters/" ? "a vízóra" : "az egyéb munka"} adat letöltésekor. `, retVal?.status));
      }
    });
  };

  await fetchListFromDB("/wmeters/");

  //make a copy from the global list from the store
  propState.wmList.current = [...waterMeters];

  if (!!newState) {
    propState.wmList.current = propState.wmList.current.map((item) => {
      let idx = newState[`${propState.actTask}`].wmlist.findIndex((wii) => wii.meter_identifier == item[types.PREFIX_WM]);
      if (!!(idx > -1)) {
        item.duty = idx;
      } else {
        item.duty = null;
      }
      return item;
    });
  }

  //make a copy from the global list from the store
  propState.sealList.current = [...seals];

  if (!!newState) {
    propState.sealList.current = propState.sealList.current.map((item) => {
      let idx = newState[`${propState.actTask}`].wmlist.findIndex((wii) => wii.plomba1_identifier == item[types.PREFIX_SEAL]);
      if (!!(idx > -1)) {
        item.duty = idx;
      } else {
        idx = newState[`${propState.actTask}`].wmlist.findIndex((wii) => wii.plomba2_identifier == item[types.PREFIX_SEAL]);
        if (!!(idx > -1)) {
          item.duty = idx;
        } else {
          item.duty = null;
        }
      }
      return item;
    });
  }
  /// Important to call the new "fetchListFromDB" after the previous settings,
  /// because it re-sets the "newState" to that of invoiceitems'
  newState = undefined;
  await fetchListFromDB("/invoiceitems/");
};
